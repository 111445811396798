import { AgChartsReact } from 'ag-charts-react'
import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useToast } from '@chakra-ui/react'

import API_ENDPOINTS from '../../../services/API/apiEndpoints.constants'
import { generalGetAPI } from '../../../services/API/general.api'
import { baseSuccessToastOptions } from '../../../utils/functions.utils'
import { ContractsPerProductDto } from '../../../utils/types/types'

const ContractsPerProductChart = (): ReactElement => {
    const translate = useTranslation().t
    const toast = useToast()
    const [contractsPerProduct, setContractsPerProduct] = useState<
        ContractsPerProductDto[]
    >([])

    const getContractsPerProduct = async (): Promise<void> => {
        try {
            const response: any = await generalGetAPI(
                API_ENDPOINTS.dashboardContractsPerProduct
            )
            if (response?.data && response?.isOk) {
                setContractsPerProduct(response.data)
            }
            if (!response.isOk) {
                toast(baseSuccessToastOptions(response.message))
            }
        } catch (error: any) {
            toast(baseSuccessToastOptions(error?.message))
        }
    }

    useEffect(() => {
        getContractsPerProduct()
    }, [])

    const createChartData = (): any[] =>
        contractsPerProduct.map((item) => ({
            productName: translate(item?.productName ?? ''),
            numberOfContracts: item.totalContractsNumber,
        }))

    const createOptions = (data: any[]): any => ({
        data,
        height: 500,
        title: {
            text: translate('contractsPerProduct'),
        },
        series: [
            {
                type: 'donut',
                angleKey: 'numberOfContracts',
                calloutLabelKey: 'productName',
                title: {
                    text: translate('totalContracts'),
                },
                innerRadiusRatio: 0.7,
                innerLabels: [
                    {
                        text: translate('total'),
                        fontSize: 16,
                        margin: 10,
                    },
                    {
                        text: data
                            .reduce((sum, d) => sum + d.numberOfContracts, 0)
                            .toString(),
                        fontSize: 24,
                    },
                ],
                strokeWidth: 3,
            },
        ],
    })

    return <AgChartsReact options={createOptions(createChartData())} />
}

export default ContractsPerProductChart
