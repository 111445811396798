import camelCase from 'lodash/camelCase'
import { MutableRefObject, ReactElement, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { DeleteIcon, EditIcon } from '@chakra-ui/icons'
import { Box, Button, Flex, IconButton } from '@chakra-ui/react'

import DynamicForm from '../../../../features/dynamicForm/DynamicForm.feature'
import PopupActionButton, {
    ButtonVariant,
} from '../../../../features/popupActionButton/PopupActionButton.feature'
import { usePartner } from '../../../../services/contexts/partner.context'
import {
    ColumnActionConfiguration,
    getSpecificKeyFromObject,
} from '../../../../utils/functions.utils'
import {
    NumberSerieDTO,
    NumberSeriesEntityType,
} from '../../../../utils/types/types'
import DynamicGrid from '../../../demo/DynamicAGGrid.component'
import { FORM_FIELDS_NUMBER_SERIES } from './NumberSeries.config'

function NumberSeriesTableComponent({
    series = [],
    onSeriesUpdate = (args: any) => {},
}: {
    series: NumberSerieDTO[]
    onSeriesUpdate?: Function
}): ReactElement {
    const translate = useTranslation().t
    const formSubmitReference: MutableRefObject<HTMLButtonElement | undefined> =
        useRef()
    const actionButtonRef = useRef<any>()
    const popupCloseReference = useRef<any>()
    const [selectedSerie, setSelectedSerie] = useState<
        NumberSerieDTO | undefined
    >()
    const { activePartner } = usePartner()

    function popupConfirmEvent(): void {
        if (formSubmitReference) {
            formSubmitReference.current?.click()
        }
    }

    function updateSeries(item: NumberSerieDTO) {
        popupCloseReference?.current?.click()
        const index = series.findIndex(
            (el) => el.entityType === item.entityType
        )
        if (index !== -1) {
            series[index] = item
        } else {
            series.push(item)
        }
        onSeriesUpdate(series)
    }

    const handleEdit = (data?: NumberSerieDTO): void => {
        setSelectedSerie(data)
        actionButtonRef?.current?.click()
    }

    const handleRemove = (item: NumberSerieDTO) => {
        const index = series.findIndex(
            (el) => el.entityType === item.entityType
        )
        index > -1 ?? series.splice(index, 1)
        onSeriesUpdate(series)
    }

    const numberSeriesHeaders = [
        {
            field: 'entityType',
            valueGetter: (params: any) =>
                translate(
                    camelCase(
                        getSpecificKeyFromObject<typeof NumberSeriesEntityType>(
                            NumberSeriesEntityType,
                            params.data?.entityType || 0
                        )
                    )
                ),
        },
        { headerName: translate('template'), field: 'template' },
        { headerName: translate('startDate'), field: 'startDate' },
        { headerName: translate('endDate'), field: 'endDate' },
        { headerName: translate('sequenceNumber'), field: 'sequenceNumber' },
        activePartner?.isDemo
            ? {
                  ...ColumnActionConfiguration(translate, (params: any) => (
                      <>
                          <IconButton
                              icon={<DeleteIcon />}
                              aria-label={'delete'}
                              variant={'outline'}
                              onClick={() => handleRemove(params.data)}
                          ></IconButton>
                          <IconButton
                              icon={<EditIcon />}
                              aria-label={'edit'}
                              variant={'outline'}
                              onClick={() => handleEdit(params.data)}
                          ></IconButton>
                      </>
                  )),
              }
            : false,
    ].filter(Boolean)

    return (
        <Box>
            {activePartner?.isDemo && (
                <Flex>
                    <Button onClick={() => handleEdit()}>Add</Button>
                </Flex>
            )}
            <DynamicGrid
                tableId="numberSeries"
                columns={numberSeriesHeaders}
                rowData={series}
                enableSidebar={false}
                enableSearch={false}
                pagination={false}
            />
            <PopupActionButton
                title={translate('numberSeries')}
                content={
                    <DynamicForm<Partial<NumberSerieDTO>>
                        formSubmitReference={formSubmitReference}
                        data={selectedSerie || {}}
                        formFields={FORM_FIELDS_NUMBER_SERIES(
                            series.map((s) => s.entityType),
                            !!selectedSerie
                        )}
                        hideSubmit={false}
                        onSubmit={updateSeries}
                        submitButtonProps={{ width: '100%' }}
                        columns={[2]}
                    />
                }
                onConfirm={popupConfirmEvent}
                isModal={true}
                buttonVariant={ButtonVariant.SOLID}
                // why is this hidden?
                actionName={'hidden'}
                buttonProps={{ display: 'none' }}
                buttonRef={actionButtonRef}
                popupCloseReference={popupCloseReference}
                hasActions={false}
            />
        </Box>
    )
}

export default NumberSeriesTableComponent
