/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-unused-vars */
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import {
    Button,
    Flex,
    Heading,
    Select,
    Spinner,
    useToast,
} from '@chakra-ui/react'

import ContractAssetsTable from '../../../../../features/genericTables/contractAssetsTable/ContractAssetsTable.component'
import withModalHOC, { ModalSharedProps } from '../../../../../hoc/modal.hoc'
import API_ENDPOINTS from '../../../../../services/API/apiEndpoints.constants'
import {
    generalGetAPI,
    generalPostAPI,
} from '../../../../../services/API/general.api'
import { useProduct } from '../../../../../services/contexts/Product.context'
import { useContractService } from '../../../../../services/contract/Contract.services'
import { baseErrorToastOptions } from '../../../../../utils/functions.utils'

import {
    AssetBalanceDTO,
    ContractAssetDTO,
    ContractAssetStatus,
    ContractDTO,
    CreateContractPreterminateOffer,
    PreterminateNumbersDTO,
    ProductType,
} from '../../../../../utils/types/types'
import SelectPreterminationOffer from './SelectOffer.component'

dayjs.extend(isBetween)

function PreterminateContract(props: ModalSharedProps): ReactElement {
    const translate = useTranslation().t
    const toast = useToast()
    const { contract } = useContractService()
    const { products } = useProduct()
    const navigate = useNavigate()
    const [selectedAssets, setSelectedAssets] = useState<ContractAssetDTO[]>([])
    const [offerBalances, setOfferBalances] = useState<AssetBalanceDTO[]>([])
    const [preterminateOffers, setPreterminateOffers] =
        useState<ContractDTO[]>()
    const [offerAssetList, setOfferAssetList] = useState<any[]>([])
    const [hasBoughtAsset, setHasBoughtAsset] = useState(true)
    const [period, setPeriod] = useState<number>(-1)
    const [contractAssets, setContractAssets] = useState<any[]>()
    const [preterminateNumbers, setPreterminateNumbers] = useState<
        PreterminateNumbersDTO[]
    >([])
    const [isCreateOffer, setIsCreateOffer] = useState(false)
    const [selectedProduct, setSelectedProduct] = useState<Number | null>(null)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        getOffer()
        getBalances()
        getEstimates()
    }, [contract])

    useEffect(() => {
        if (contract?.contractAssets?.length) {
            setContractAssets(
                mergeAssetsWithPreterminateNumbers(
                    contract.contractAssets?.filter(
                        (ca) => ca.status === ContractAssetStatus.Active
                    ),
                    preterminateNumbers
                )
            )
        }
    }, [contract.contractNumber, preterminateNumbers])

    useEffect(() => {
        offerBalances.length > 0 && findCurrentMonth()
    }, [offerBalances])

    const onSelectionChanged = (data: any) => {
        const selectedNodes = data.api.getSelectedNodes()
        const selectedData = selectedNodes.map((node: any) => node.data)
        if (JSON.stringify(selectedData) !== JSON.stringify(selectedAssets)) {
            setSelectedAssets([...selectedData])
        }
    }

    const getOffer = async (): Promise<void> => {
        setIsLoading(true)
        if (contract.contractNumber) {
            try {
                const response = await generalGetAPI(
                    API_ENDPOINTS.contractAssetsPreterminateOffer(
                        contract.contractNumber
                    )
                )
                if (response.isOk) {
                    if (response.data.length > 0) {
                        setPreterminateOffers(response.data)
                    } else {
                        setIsCreateOffer(true)
                    }
                }
            } catch (error) {
                console.error(error)
            }
        }
        setIsLoading(false)
    }

    const getEstimates = async (): Promise<void> => {
        setIsLoading(true)
        if (contract.contractNumber) {
            try {
                const response = await generalGetAPI(
                    API_ENDPOINTS.contractAssetsPreterminateOfferEstimate(
                        contract.contractNumber
                    )
                )
                if (response.isOk) {
                    if (response.data?.preterminateNumbers?.length > 0) {
                        setPreterminateNumbers(
                            response.data?.preterminateNumbers
                        )
                    } else {
                        setIsCreateOffer(true)
                    }
                }
            } catch (error) {
                console.error(error)
            }
        }
        setIsLoading(false)
    }

    const getBalances = async (): Promise<void> => {
        if (contract?.contractNumber) {
            try {
                setIsLoading(true)
                const response = await generalPostAPI(
                    API_ENDPOINTS.contractAssetsPreterminateOfferBalances(
                        contract.contractNumber
                    ),
                    {
                        contractNumber: contract.contractNumber,
                    }
                )
                if (response.isOk) {
                    setOfferBalances(response.data)
                }
            } catch (error) {
                toast(baseErrorToastOptions(translate('apiFail')))
                props.onClose()
                console.error(error)
            }
            setIsLoading(false)
        }
    }

    const createOffer = async (): Promise<void> => {
        if (contract?.contractNumber) {
            setIsLoading(true)
            try {
                const response = await generalPostAPI(
                    API_ENDPOINTS.contractAssetsPreterminateOffer(
                        contract.contractNumber
                    ),
                    {
                        contractNumber: contract.contractNumber,
                        productId: selectedProduct,
                        customerNumber: contract?.customerNumber,
                        customerBoughtAsset: hasBoughtAsset,
                        month: period,
                        contractAssets: offerAssetList?.map((a) => ({
                            assetNumber: a.assetNumber,
                            agreedPrice: a.agreedRestValue,
                        })),
                    } as unknown as CreateContractPreterminateOffer
                )
                if (response.isOk) {
                    navigate(`/contracts/${response.data.contractNumber}`)
                    window.location.reload()
                } else {
                    toast(baseErrorToastOptions(response.message))
                }
            } catch (error) {
                setIsCreateOffer(true)
                toast(
                    baseErrorToastOptions(
                        translate('unableCreatePreterminationOffer')
                    )
                )
                console.error(error)
            }
            setIsLoading(false)
        }
    }

    const findCurrentMonth = () => {
        const index = offerBalances.findIndex((elem) => {
            if (
                dayjs(new Date()).isBetween(
                    elem.periodStartDate,
                    elem.periodEndDate
                )
            ) {
                return true
            }
            return false
        })
        if (index !== -1) {
            setPeriod(
                offerBalances[index].period === -1
                    ? 0
                    : offerBalances[index].period
            )
        }
        if (index === -1) {
            setPeriod(0)
        }
    }

    function updateOfferAssetList(updatedAsset: any) {
        const index = offerAssetList.findIndex(
            (a) => a.assetNumber === updatedAsset.assetNumber
        )
        if (index === -1) {
            setOfferAssetList([...offerAssetList, updatedAsset])
        } else {
            offerAssetList[index] = updatedAsset
            setOfferAssetList([...offerAssetList])
        }
    }

    function mergeAssetsWithPreterminateNumbers(
        assets: ContractAssetDTO[],
        preterminateNumbers: PreterminateNumbersDTO[]
    ) {
        // Create a dictionary for quick lookup of preterminate numbers by assetNumber
        const preterminateDict: { [key: string]: PreterminateNumbersDTO } = {}
        preterminateNumbers.forEach((item) => {
            if (item.assetNumber) {
                preterminateDict[item.assetNumber] = item
            }
        })

        // Iterate through each asset and merge with preterminate numbers if assetNumber matches
        const mergedAssets = assets.map((asset) => {
            const { assetNumber } = asset
            if (assetNumber && preterminateDict[assetNumber]) {
                return {
                    ...asset,
                    currentBalance:
                        preterminateDict[assetNumber].currentBalance,
                    remainingInterestEarnings:
                        preterminateDict[assetNumber].remainingInterestEarnings,
                    expirationDate:
                        preterminateDict[assetNumber].expirationDate,
                }
            }
            return asset
        })
        return mergedAssets
    }

    return !isLoading ? (
        <div>
            {preterminateOffers && !isCreateOffer && (
                <Flex mb={4} gap={4} alignItems={'center'}>
                    <SelectPreterminationOffer offers={preterminateOffers} />
                    {translate('or')}
                    <Button
                        variant={'outline'}
                        onClick={() => setIsCreateOffer(true)}
                    >
                        {translate('createPreterminationOffer')}
                    </Button>
                </Flex>
            )}

            {isCreateOffer && (
                <>
                    <Heading size={'md'} mb={4}>
                        {translate('createPreterminationOffer')}
                    </Heading>
                    <Select
                        my={6}
                        width={'70%'}
                        placeholder="Select Product"
                        onChange={(elem) =>
                            setSelectedProduct(Number(elem.target.value))
                        }
                    >
                        {products
                            ?.filter((p) => p?.productType === ProductType.Sale)
                            ?.map((product) => (
                                <option key={product.id} value={product.id}>
                                    {`${product.name}`}
                                </option>
                            ))}
                    </Select>
                    {contractAssets?.length && (
                        <ContractAssetsTable
                            assets={contractAssets}
                            hasSelection={true}
                            hasSearch={false}
                            onSelectionChanged={onSelectionChanged}
                            variation="preterminate"
                            cellValueChanged={(event: any) => {
                                if (event.data) {
                                    updateOfferAssetList({ ...event.data })
                                }
                            }}
                        />
                    )}
                    <Flex gap={4} justifyContent={'flex-end'} mt={8}>
                        <Button
                            variant={'outline'}
                            onClick={() => {
                                !preterminateOffers && props.onClose()
                                setIsCreateOffer(false)
                            }}
                        >
                            {translate('cancel')}
                        </Button>
                        <Button
                            onClick={() => {
                                setIsCreateOffer(false)
                                createOffer()
                            }}
                            isDisabled={
                                !selectedProduct ||
                                selectedAssets.length === 0 ||
                                offerAssetList.length === 0 ||
                                offerAssetList?.some(
                                    (a) => !a?.agreedRestValue // || !a?.fee
                                )
                            }
                        >
                            {translate('createOffer')}
                        </Button>
                    </Flex>
                </>
            )}
        </div>
    ) : (
        <Flex justifyContent={'center'} height={'400px'} alignItems={'center'}>
            <Spinner />
        </Flex>
    )
}

export default withModalHOC(PreterminateContract)
