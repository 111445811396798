import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Box } from '@chakra-ui/react'

import withModalHOC, { ModalSharedProps } from '../../hoc/modal.hoc'
import DynamicGrid from '../../pages/demo/DynamicAGGrid.component'
import API_ENDPOINTS from '../../services/API/apiEndpoints.constants'
import useIsMounted from '../../services/hooks/isMounted'
import { getAllPagesHandlerPrompt } from '../../utils/pagination/pagination.util'
import { ContractAssetDTO, GetBaseAssetDetail } from '../../utils/types/types'
import { ASSET_LIST_HEADERS } from './existingAsset.feature.config'

interface AddExistingAssetInterface extends ModalSharedProps {
    onSelected(item: GetBaseAssetDetail): void
    existingContractAssets?: ContractAssetDTO[]
}

function AddExistingAssetContainer({
    onSelected,
    existingContractAssets = [],
}: AddExistingAssetInterface): ReactElement {
    const [baseAssets, setBaseAssets] = useState<GetBaseAssetDetail[]>()
    const isMounted = useIsMounted()
    const translate = useTranslation().t

    function removedAddedAssets(
        items: GetBaseAssetDetail[]
    ): GetBaseAssetDetail[] {
        let filteredItems: GetBaseAssetDetail[] = [...items]
        existingContractAssets.forEach((asset) => {
            filteredItems = filteredItems.filter(
                (item) => item.baseAssetNumber !== asset.baseAssetNumber
            )
        })
        return filteredItems
    }

    const retrieveBaseAssets = async (): Promise<void> => {
        await getAllPagesHandlerPrompt(
            API_ENDPOINTS.assetBasePaginated,
            (data: GetBaseAssetDetail[]) => {
                setBaseAssets(removedAddedAssets(data))
            }
        )
    }

    useEffect((): void => {
        if (isMounted()) {
            retrieveBaseAssets()
        }
    }, [isMounted])

    return (
        <Box mt={8}>
            <DynamicGrid
                columns={ASSET_LIST_HEADERS(translate, true, false, onSelected)}
                rowData={baseAssets}
                tableId={'existingAssetTable'}
            />
        </Box>
    )
}

export default withModalHOC<AddExistingAssetInterface>(
    AddExistingAssetContainer
)
