import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, IconButton, Stack, Tooltip, useBoolean } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { ViewIcon } from '@chakra-ui/icons'

import ExportedInvoiceLines from '../../../../../../app/config/download/exportedInvoiceLines.config.json'
import DownloadActions from '../../../../../../components/downloadActions/DownloadActions.component'
import HighlightComponent from '../../../../../../components/highlight/Highlight.component'
import API_ENDPOINTS from '../../../../../../services/API/apiEndpoints.constants'
import { generalGetAPI } from '../../../../../../services/API/general.api'
import { useNumberFormatterContext } from '../../../../../../services/contexts/NumberFormatter.context'
import {
    ExportStatus,
    InvoiceDTO,
    InvoiceLineDTO,
} from '../../../../../../utils/types/types'
import DynamicGrid from '../../../../../demo/DynamicAGGrid.component'
import { EXPORT_HEADERS } from '../../Export.config'
import { ColumnActionConfiguration } from '../../../../../../utils/functions.utils'
import { InvoiceLinesTableModal } from '../../../../../../features/genericTables/invoiceLinesTable/InvoiceLinesTable.component'

export default function ExportedPage({
    showInvoiceLinesPopup = true,
}): ReactElement {
    const [selectedItems, setSelectedItems] = useState<InvoiceDTO[]>([])
    const [data, setData] = useState<InvoiceDTO[]>()
    const [isDownloadEnabled, setDownloadEnabled] = useState(false)
    const { formatValue } = useNumberFormatterContext()
    const translate = useTranslation().t
    const [invoiceLines, setInvoiceLines] = useState<InvoiceLineDTO[]>([])
    const [isInvoiceLinesOpen, setInvoiceLinesOpen] = useBoolean()
    const navigate = useNavigate()

    function calculateTotal(): string {
        let tempTotal = 0
        selectedItems.forEach((item: InvoiceDTO) => {
            tempTotal += item.amountExcludingVat!
        })
        return tempTotal.toFixed(2)
    }

    useEffect(() => {
        generalGetAPI(
            `${API_ENDPOINTS.invoiceExport}?ExportStatus=${ExportStatus.Exported}`
        ).then((response) => {
            response.isOk && setData(response.data.items)
        })
    }, [])

    const selectAllElements = async (): Promise<void> => {
        data && setSelectedItems([...data])
    }

    const fields = EXPORT_HEADERS(
        translate,
        formatValue,
        false,
        isDownloadEnabled
    )

    return (
        <>
            <Box>
                <Box display={'flex'} gap={4}>
                    <HighlightComponent
                        title={translate('totalExcludingVat')}
                        info={calculateTotal()}
                    />
                    <HighlightComponent
                        title={translate('selectedItems')}
                        info={selectedItems.length}
                        hasBorder={true}
                    />
                </Box>
            </Box>
            <Stack spacing={4}>
                <DynamicGrid
                    tableId="exportedTable"
                    columns={[
                        ...fields,
                        ColumnActionConfiguration(translate, (params: any) => (
                            <Tooltip
                                label={translate('view')}
                                placement="top"
                                hasArrow
                            >
                                <IconButton
                                    variant={'outline'}
                                    size="sm"
                                    aria-label="View"
                                    onClick={(): void =>
                                        showInvoiceLinesPopup
                                            ? (setInvoiceLines(
                                                  params?.data?.lines
                                              ),
                                              setInvoiceLinesOpen.on())
                                            : navigate(
                                                  `/financial/invoices/${params.data.invoiceNumber}`,
                                                  {
                                                      state: params.data,
                                                  }
                                              )
                                    }
                                    icon={<ViewIcon />}
                                />
                            </Tooltip>
                        )),
                    ]}
                    rowData={data}
                    onSelectionChanged={(values: any): void => {
                        const selectedNodes = values.api.getSelectedNodes()
                        const selectedData = selectedNodes.map(
                            (node: any) => node.data
                        )
                        setSelectedItems(selectedData)
                    }}
                    headers={
                        <>
                            <DownloadActions
                                isDownloadEnabled={isDownloadEnabled}
                                setDownloadEnabled={setDownloadEnabled}
                                selectAllElements={selectAllElements}
                                selectedItems={selectedItems}
                                downloadConfig={ExportedInvoiceLines}
                                resetSelectedItems={() => setSelectedItems([])}
                            />
                        </>
                    }
                />
            </Stack>
            {isInvoiceLinesOpen && showInvoiceLinesPopup && (
                <InvoiceLinesTableModal
                    invoiceLines={invoiceLines}
                    isModalOpen={isInvoiceLinesOpen}
                    modalTitle={translate('invoiceLines')}
                    onClose={(): void => {
                        setInvoiceLinesOpen.off()
                    }}
                />
            )}
        </>
    )
}
