/* eslint-disable no-unused-vars */
import { toast } from 'react-toastify'

import { generalGetAPI } from '../../services/API/general.api'

export async function getAllPagesHandlerPrompt(
    endpoint: string,
    setterCallback: (data: any) => void,
    params = {}
): Promise<void> {
    const response = await generalGetAPI(endpoint, {
        ...(!endpoint.includes('pageSize') && {
            params: { PageSize: 500, ...params },
        }),
    })
    if (!response.isOk) {
        toast.success(response.message)
        return
    }
    if (response.data && !response.data?.items) {
        setterCallback(response.data)
        return
    }
    setterCallback(response.data.items)
    if (response.data.next) {
        // eslint-disable-next-line consistent-return
        return getAllPagesHandlerPrompt(response.data.next, setterCallback)
    }
}
