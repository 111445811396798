import debounce from 'lodash/debounce'
import {
    createContext,
    Dispatch,
    ReactElement,
    ReactNode,
    SetStateAction,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import { useToast } from '@chakra-ui/react'

import {
    baseErrorToastOptions,
    baseSuccessToastOptions,
    isEmpty,
    validateContract,
} from '../../utils/functions.utils'
import { getAllPagesHandlerPrompt } from '../../utils/pagination/pagination.util'
import {
    ActionsAllowed,
    ContractDTO,
    DocumentMetaDataDto,
    ProductDTO,
} from '../../utils/types/types'
import API_ENDPOINTS from '../API/apiEndpoints.constants'
import {
    generalGetAPI,
    generalPostAPI,
    generalPutAPI,
} from '../API/general.api'
import useIsMounted from '../hooks/isMounted'
import {
    CustomContractFinancingType,
    validateAssets,
    validateTransientFees,
} from './Contract.services.config'

type PartialContractDTO = Partial<ContractDTO>
interface IContractContext {
    actionsAllowed: ActionsAllowed
    cancelContract: () => void
    contract: PartialContractDTO
    contractList: PartialContractDTO[]
    documents: DocumentMetaDataDto[]
    enableContractSave: boolean
    fetchDocuments: Function
    financing: CustomContractFinancingType
    getAllContracts: () => Promise<void>
    getContract: Function
    hasErrors: boolean
    isContractActiveLocked: boolean
    isLoading: boolean
    originalContract: PartialContractDTO
    product: ProductDTO
    saveContract: Function
    setEnableContractSave: Dispatch<SetStateAction<boolean>>
    setHasErrors: Function
    setOriginalContract: Function
    updateContract: Function
    updateDocuments: Function
    updateFinancing: Function
}

interface IContractContextProviderProps {
    children: ReactNode
}

export const ContractContext = createContext({} as IContractContext)

export const useContractService = (): IContractContext =>
    useContext(ContractContext)

export const ContractServiceProvider = ({
    children,
}: IContractContextProviderProps): ReactElement => {
    const translate = useTranslation().t
    const params = useParams()
    const toast = useToast()
    const isMounted = useIsMounted()
    const navigate = useNavigate()

    const [originalContract, setOriginalContract] =
        useState<PartialContractDTO>({})
    const [contract, setContract] = useState<PartialContractDTO>({})
    const [actionsAllowed, setActionsAllowed] = useState<ActionsAllowed>()
    const [contractList, setContractList] = useState<PartialContractDTO[]>([])
    const [hasErrors, setHasErrors] = useState<boolean>(false)
    const [isLoading, setLoading] = useState<boolean>(true)
    const [enableContractSave, setEnableContractSave] = useState<boolean>(true)
    const [product, setProduct] = useState<Partial<ProductDTO>>({})
    const [financing, setFinancing] = useState<
        Partial<CustomContractFinancingType>
    >({})
    const contractId = params?.id
    const [documents, setDocuments] = useState<DocumentMetaDataDto[]>([])

    useEffect((): void => {
        if (
            isMounted() &&
            contractId &&
            (!contract?.contractNumber ||
                contract.contractNumber !== contractId)
        ) {
            getContract().then(() => setOriginalContract(contract))
        }
    }, [JSON.stringify(params), isMounted()])

    useEffect(() => {
        contract.productId !== undefined && retrieveProduct(contract.productId)
    }, [contract.productId])

    useEffect(() => {
        if (!isEmpty(contract)) debounceUpdateFinancing(contract as any)
    }, [JSON.stringify(contract), JSON.stringify(contract?.contractAssets)])

    async function getContract(contractIdParam?: string): Promise<void> {
        if (!contractId && !contractIdParam) return
        const response = await generalGetAPI(
            `${API_ENDPOINTS.contract}/${contractIdParam || contractId}`
        )
        if (!response.isOk) return
        setLoading(false)
        setContract({ ...response.data.contract })
        setActionsAllowed({ ...response.data.actionsAllowed })
    }

    const debounceUpdateFinancing = useCallback(
        debounce((c: ContractDTO) => updateFinancing(c), 1000),
        []
    )

    const updateFinancing = async (
        contractInit?: ContractDTO
    ): Promise<void> => {
        let currentContract = contract
        if (contractInit) currentContract = contractInit
        if (!validateContract(currentContract as ContractDTO)) return

        currentContract.contractAssets = currentContract.contractAssets?.map(
            (ca: any) => ({
                ...ca,
                assetTypeId: ca?.assetTypeData?.id
                    ? ca?.assetTypeData?.id
                    : ca?.assetTypeId,
            })
        )

        const response = await generalPostAPI(
            API_ENDPOINTS.contractActionsCalculate,
            { contract: currentContract }
        )
        if (response.isOk) {
            setFinancing(response.data)
        }
    }

    async function retrieveProduct(productId: number): Promise<void> {
        const response = await generalGetAPI(
            `${API_ENDPOINTS.product}/${productId}`
        )
        if (response.isOk) {
            setProduct(response?.data)
        }
    }

    const updateContract = (updatedContract: ContractDTO): void => {
        // Add special logic if needed here
        setContract({ ...updatedContract })
    }

    const saveContract = async (
        optionalContract?: ContractDTO
    ): Promise<void> => {
        if (!enableContractSave) return
        const contractToUpdate = optionalContract ?? contract

        if (!contractToUpdate?.contractAssets?.length) {
            toast(baseErrorToastOptions(translate('requiredAsset')))
            return
        }

        if (!validateContract(contractToUpdate as ContractDTO) || hasErrors) {
            toast(baseErrorToastOptions(translate('notValidContract')))
            return
        }

        contractToUpdate.contractAssets = validateAssets(
            contractToUpdate.contractAssets
        )

        contractToUpdate.transientFees = validateTransientFees(
            contractToUpdate.transientFees
        )

        contractToUpdate.interest = Number(contractToUpdate?.interest)
        contractToUpdate.variableInterest = Number(
            contractToUpdate?.variableInterest
        )
        contractToUpdate.deposit = Number(contractToUpdate?.deposit)
        contractToUpdate.creationFee = Number(contractToUpdate?.creationFee)
        contractToUpdate.contractAssets?.map((ca) => ({
            ...ca,
            downpayment: Number(ca?.downpayment),
        }))
        contractToUpdate.contractAssets = contractToUpdate.contractAssets?.map(
            (ca: any) => ({
                ...ca,
                assetTypeId: ca?.assetTypeData?.id
                    ? ca?.assetTypeData?.id
                    : ca?.assetTypeId,
            })
        )

        const response = await generalPutAPI(
            API_ENDPOINTS.contract,
            contractToUpdate as any
        )
        if (response.isOk) {
            toast(baseSuccessToastOptions(translate('contractUpdated')))
            getContract()
        } else {
            toast(baseErrorToastOptions(response.message))
        }
    }

    async function getAllContracts(): Promise<void> {
        return getAllPagesHandlerPrompt(API_ENDPOINTS.contract, (data: any) => {
            setContractList((prevValue) => [...prevValue, ...data])
        })
    }

    const cancelContract = async (): Promise<void> => {
        if (contract.contractNumber) {
            const cancelResponse = await generalPutAPI(
                API_ENDPOINTS.contractActionsCancelContract,
                { contractNumber: contract.contractNumber }
            )
            if (cancelResponse.isOk) {
                toast(baseSuccessToastOptions(translate('contractCanceled')))
                navigate('/contracts')
            } else {
                toast(baseErrorToastOptions(cancelResponse.message))
            }
        }
    }

    const fetchDocuments = async (): Promise<void> => {
        const documentsResponse = await generalGetAPI(
            `${API_ENDPOINTS.documentByContract}${contract.contractNumber}`
        )
        if (documentsResponse.isOk) {
            setDocuments(documentsResponse.data)
        }
    }

    const updateDocuments = (documentItems: DocumentMetaDataDto[]) => {
        setDocuments([...documentItems])
    }

    const props = {
        actionsAllowed,
        contract,
        contractList,
        documents,
        enableContractSave,
        fetchDocuments,
        financing,
        getAllContracts,
        getContract,
        isLoading,
        hasErrors,
        setHasErrors,
        originalContract,
        product,
        saveContract,
        setEnableContractSave,
        setOriginalContract,
        updateContract,
        updateDocuments,
        updateFinancing,
        cancelContract,
    }

    return (
        <ContractContext.Provider value={props as any}>
            {children}
        </ContractContext.Provider>
    )
}
