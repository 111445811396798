import {
    createContext,
    ReactElement,
    ReactNode,
    useContext,
    useEffect,
    useState,
} from 'react'
import { useTranslation } from 'react-i18next'

import { useToast } from '@chakra-ui/react'

import { baseInfoToastOptions } from '../../utils/functions.utils'
import { CustomerDTO } from '../../utils/types/types'
import API_ENDPOINTS from '../API/apiEndpoints.constants'
import { generalGetAPI } from '../API/general.api'

interface CustomerChangeContextInterface {
    customer: CustomerDTO | undefined
    companyCustomers: CustomerDTO[]
    creditSafeCustomers: CustomerDTO[]
    customerSearch: string
    setCustomerSearch: Function
    customerSearchPending: boolean
    handleCustomerSearch(): void
    handleResetCustomerSearch(): void
    setCustomer: Function
}

const CustomerChangeContext = createContext(
    {} as CustomerChangeContextInterface
)

export const CustomerChangeContextProvider = ({
    children,
}: {
    children: ReactNode
}): ReactElement => {
    const [customer, setCustomer] = useState<CustomerDTO>()
    const [companyCustomers, setCompanyCustomer] = useState<CustomerDTO[]>([])
    const [creditSafeCustomers, setCreditSafeCustomers] = useState<
        CustomerDTO[]
    >([])
    const [customerSearch, setCustomerSearch] = useState<string>('')
    const [customerSearchPending, setCustomerSearchPending] =
        useState<boolean>(false)
    const toast = useToast()
    const translate = useTranslation().t

    const retrieveCustomers = async (): Promise<void> => {
        const response = await generalGetAPI(API_ENDPOINTS.customer)
        if (response.isOk) setCompanyCustomer(response.data)
    }

    useEffect((): void => {
        retrieveCustomers()
    }, [])

    const handleCustomerSearch = async (): Promise<void> => {
        if (!customerSearch || customerSearch.length < 0) {
            setCreditSafeCustomers([])
            return
        }
        if (customerSearchPending) {
            toast(baseInfoToastOptions(translate('requestPending')))
            return
        }
        setCustomerSearchPending(true)
        const data = await generalGetAPI(
            `${API_ENDPOINTS.creditSafe}?Query=${customerSearch}`
        )
        setCustomerSearchPending(false)
        if (!data.isOk) return
        const creditSafeCustomersList = data.data.map(
            (customerIteration: any) => ({
                ...customerIteration,
                cvr: customerIteration.registrationNumber,
            })
        )
        setCreditSafeCustomers(creditSafeCustomersList)
    }

    const handleResetCustomerSearch = (): void => {
        setCustomerSearch('')
        setCreditSafeCustomers([])
        setCustomerSearchPending(false)
    }

    return (
        <CustomerChangeContext.Provider
            value={{
                customer,
                companyCustomers,
                creditSafeCustomers,
                customerSearch,
                customerSearchPending,
                setCustomer,
                setCustomerSearch,
                handleCustomerSearch,
                handleResetCustomerSearch,
            }}
        >
            {children}
        </CustomerChangeContext.Provider>
    )
}

export const useCustomerChange = (): CustomerChangeContextInterface =>
    useContext(CustomerChangeContext)
