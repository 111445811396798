import { AgChartsReact } from 'ag-charts-react'
import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useToast } from '@chakra-ui/react'

import API_ENDPOINTS from '../../../services/API/apiEndpoints.constants'
import { generalGetAPI } from '../../../services/API/general.api'
import { baseSuccessToastOptions } from '../../../utils/functions.utils'
import { ActivatedAssetsVolumeDto } from '../../../utils/types/types'

const AssetVolumeChart = (): ReactElement => {
    const translate = useTranslation().t
    const toast = useToast()
    const [assetData, setAssetData] = useState<ActivatedAssetsVolumeDto>()
    const [assetVolume, setAssetVolume] =
        useState<{ value: any; status: string }[]>()

    const getAssetVolume = async (): Promise<void> => {
        try {
            const response: any = await generalGetAPI(
                API_ENDPOINTS.dashboardActivatedAssetVolume
            )
            if (response?.data && response?.isOk) {
                setAssetData(response.data)
                setAssetVolume(
                    Object.entries(response.data).map((entries) => ({
                        value: entries[1],
                        status: translate(entries[0]),
                    }))
                )
            }
            if (!response.isOk) {
                toast(baseSuccessToastOptions(response.message))
            }
        } catch (error: any) {
            toast(baseSuccessToastOptions(error?.message))
        }
    }

    useEffect(() => {
        getAssetVolume()
    }, [])

    const createOptions = (data: any): any => ({
        data,
        height: 500,
        title: {
            text: translate('activatedAssetVolume'),
        },
        series: [
            {
                type: 'donut',
                angleKey: 'value',
                calloutLabelKey: 'status',
                // title: {
                //     text: 'Asset Contracts',
                // },
                innerRadiusRatio: 0.7,
                innerLabels: [
                    {
                        text: translate('activeAssets'),
                        fontSize: 16,
                        margin: 10,
                    },
                    {
                        text: assetData?.activatedAssetsNumber.toString(),
                        fontSize: 24,
                    },
                ],
                strokeWidth: 3,
            },
        ],
    })

    return <AgChartsReact options={createOptions(assetVolume)} />
}

export default AssetVolumeChart
