import { ReactElement } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import {
    Box,
    CloseButton,
    Flex,
    Img,
    Menu,
    MenuButton,
    MenuList,
    Text,
    useColorModeValue,
} from '@chakra-ui/react'

import { ReactComponent as Logo } from '../../../../assets/images/LeneoFullLogo.svg'
import ErrorBoundaryWrapper from '../../../../features/errorBoundary/ErrorBoundary.feature'
import { useLayout } from '../../../../services/contexts/LayoutServices.context'
import { usePartner } from '../../../../services/contexts/partner.context'
import { useUser } from '../../../../services/contexts/user.context'
import ColorModeSwitcher from '../../../colorModeSwitcher/ColorModeSwitcher.component'
import UserActions from '../userActions/UserActions'
import NavigationLinks from './component/NavigationLinks.component'
import PartnersMenu from './component/PartnersMenu.component'

export default function SidebarComponent(): ReactElement {
    const { activePartner } = usePartner()
    const { isMobile, isMenuOpen, closeMenu } = useLayout()
    const { user: auth0user } = useAuth0()
    const { user } = useUser()

    function firstLastNameBuilder(name: string): string {
        const names = name.split(' ')
        return `${names[0]} ${names[names.length - 1]}`
    }

    return (
        <Box
            background={useColorModeValue('primary.800', 'secondary.850')}
            w={{ base: 'full', md: '100%' }}
            pos="fixed"
            h={{ base: isMenuOpen && isMobile ? '100%' : '0vh', md: 'full' }}
            position={'relative'}
        >
            <Flex
                h="103"
                alignItems="center"
                justifyContent={'space-between'}
                mx="8"
            >
                <Flex gap={5} alignItems={'center'}>
                    <Flex
                        flexDirection={'column'}
                        gap={2}
                        alignItems={'baseline'}
                        justifyContent={'flexStart'}
                    >
                        {activePartner?.uiLogoData ? (
                            <Img
                                maxH={'50px'}
                                src={`data:image/png;base64,${activePartner?.uiLogoData}`}
                            />
                        ) : (
                            <Logo />
                        )}
                        <Text
                            color={useColorModeValue(
                                'primary.50',
                                'primary.50'
                            )}
                            fontSize={'sm'}
                        >
                            {activePartner?.name}
                        </Text>
                    </Flex>
                </Flex>
                <PartnersMenu currentPartner={activePartner} />
                {isMobile && <CloseButton onClick={closeMenu} />}
            </Flex>
            <Flex
                flexWrap={'wrap'}
                justifyContent="space-between"
                height={'calc(100vh - 90px)'}
            >
                <Box width={'100%'}>
                    <ErrorBoundaryWrapper id="sidebar-navigation-links-error">
                        <NavigationLinks />
                    </ErrorBoundaryWrapper>
                </Box>
                <Box
                    display={isMobile ? 'none' : 'flex'}
                    gap={'0.5em'}
                    flexWrap={'wrap'}
                    width={'100%'}
                    alignContent={'flex-end'}
                    mx={{ base: 0, md: '20px' }}
                    my={'20px'}
                    pb={2}
                >
                    <Flex
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        w={'100%'}
                    >
                        <Menu>
                            <MenuButton w="100%" padding={'2'}>
                                <Flex gap={'1em'} alignItems={'center'}>
                                    <Img
                                        rounded={'full'}
                                        width={'1.875rem'}
                                        height={'1.875rem'}
                                        src={auth0user?.picture}
                                        alt="avatar"
                                    />
                                    {firstLastNameBuilder(user.name || '')}
                                </Flex>
                            </MenuButton>

                            <MenuList border="none" padding={0}>
                                {UserActions().map((Item, index) => (
                                    <Box key={index} p={0}>
                                        {Item}
                                    </Box>
                                ))}
                            </MenuList>
                        </Menu>
                        <ColorModeSwitcher />
                    </Flex>
                </Box>
            </Flex>
        </Box>
    )
}
