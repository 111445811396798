import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import {
    Checkbox,
    Container,
    FormControl,
    FormLabel,
    Input,
    Select,
    SimpleGrid,
    Spinner,
    TabPanel,
    TabPanels,
} from '@chakra-ui/react'

import PageContainer, {
    TabListType,
} from '../../../components/pageContainer/PageContainer.component'
import FileUpload from '../../../features/fileUpload/FileUpload.component'
import API_ENDPOINTS from '../../../services/API/apiEndpoints.constants'
import { generalGetAPI } from '../../../services/API/general.api'
import { useProduct } from '../../../services/contexts/Product.context'
import { ServiceContextProvider } from '../../../services/contexts/Service.context'
import {
    ExternalPartnerSetup,
    GetVatDetail,
    ProductServiceDTO,
} from '../../../utils/types/types'

interface ERPProductInterface {
    name: string
    productNumber: string | number
}

const tabList: TabListType[] = [
    { text: 'details', id: 0 },
    { text: 'erpMapping', id: 1 },
    { text: 'productMapping', id: 2 },
]

function EditServiceSetup(): ReactElement {
    const [service, setService] = useState<any>()
    const { products } = useProduct()
    const [VATCodes, setVATCodes] = useState<GetVatDetail[]>([])
    const [ERPProducts, setERPProducts] = useState<ERPProductInterface[]>([])
    const [ERPSetupType, setERPSetupType] =
        useState<ExternalPartnerSetup | null>(null)
    const { id } = useParams()
    const translate = useTranslation().t

    useEffect(() => {
        retrieveVATCodes()
        retrieveERPProducts()
    }, [])

    useEffect(() => {
        const getService = async (baseAssetNumber: string) => {
            const response = await generalGetAPI(
                `${API_ENDPOINTS.service}/${baseAssetNumber}`
            )
            if (response.isOk) setService({ ...response.data })
        }
        if (id) getService(id)
    }, [id])

    const retrieveVATCodes = async () => {
        const request = await generalGetAPI(API_ENDPOINTS.vat)
        if (!request.isOk) return
        setVATCodes(request.data)
    }

    const retrieveERPProducts = async () => {
        const setupResponse = await generalGetAPI(API_ENDPOINTS.ERPSetup)
        if (!setupResponse.isOk) return

        const { setupType } = setupResponse.data
        setERPSetupType(setupType as ExternalPartnerSetup)
        const request = await generalGetAPI(
            `${API_ENDPOINTS.ERPSetupProducts}/${setupType}`
        )
        if (!request.isOk) return

        setERPProducts(request.data)
    }

    const handleServiceUpdate = (objectToUpdate: {
        [key: string]: any
    }): void => {
        setService({ ...service, ...objectToUpdate })
    }

    const handleVATSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const SelectedVAT = VATCodes.find(
            (VATCode) => VATCode.id === parseInt(event.target.value)
        )
        if (!SelectedVAT) return
        handleServiceUpdate({
            vatId: SelectedVAT.id,
            vatPercentage: SelectedVAT.percentage,
        })
    }

    const handleProductUpdate = (
        event: React.ChangeEvent<HTMLInputElement>
    ): void => {
        const { value } = event.target
        const product = products?.find(
            (iteratedProduct) => iteratedProduct.id === parseInt(value)
        )
        if (!product) return
        const newProductDTOInstance: ProductServiceDTO = {
            isSelectable: true,
            productId: product.id,
        }
        if (!service?.productServices) {
            handleServiceUpdate({ productServices: [newProductDTOInstance] })
            return
        }
        if (
            service.productServices.some(
                (productService) =>
                    productService.productId === newProductDTOInstance.productId
            )
        ) {
            const newProducts = service.productServices.filter(
                (productService) =>
                    productService.productId !== newProductDTOInstance.productId
            )
            handleServiceUpdate({ productServices: [...newProducts] })
        } else {
            handleServiceUpdate({
                productServices: [
                    ...service.productServices,
                    newProductDTOInstance,
                ],
            })
        }
    }

    const isCheckboxChecked = (productId: number): boolean => {
        if (!service?.productServices) return false
        return service.productServices.some((ps) => ps.productId === productId)
    }

    return (
        <PageContainer tabsList={tabList}>
            <Container maxW="6xl">
                <TabPanels>
                    <TabPanel>
                        <SimpleGrid columns={2} spacing={4} mb={4}>
                            <FormControl>
                                <FormLabel>
                                    {translate('description')}
                                </FormLabel>
                                <Input
                                    value={service?.description ?? ''}
                                    onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                        handleServiceUpdate({
                                            description: event.target.value,
                                        })
                                    }}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>
                                    {translate('selectVATCode')}
                                </FormLabel>

                                <Select
                                    value={service?.vatId}
                                    onChange={handleVATSelect}
                                >
                                    <option value={0}>
                                        {translate('selectVATCode')}
                                    </option>
                                    {VATCodes.map((VATCode, i) => (
                                        <option key={i} value={VATCode.id}>
                                            {VATCode.name}
                                        </option>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl>
                                <FormLabel>
                                    {translate('contractText')}
                                </FormLabel>
                                <Input
                                    value={service?.contractText ?? ''}
                                    onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                        handleServiceUpdate({
                                            contractText: event.target.value,
                                        })
                                    }}
                                />
                            </FormControl>
                        </SimpleGrid>
                        <FileUpload
                            handleSubmission={(data: any) => {
                                console.log('file upload', data)
                            }}
                        />
                    </TabPanel>
                    <TabPanel>
                        <FormLabel>{translate('erpMapping')}</FormLabel>
                        <FormControl>
                            {ERPSetupType !==
                                ExternalPartnerSetup.ErpAlternativeFileCreation && (
                                <Select
                                    value={service?.erpProductId}
                                    onChange={(
                                        event: React.ChangeEvent<HTMLSelectElement>
                                    ) => {
                                        const { value } = event.target
                                        handleServiceUpdate({
                                            erpProductId: value,
                                        })
                                    }}
                                >
                                    <option value={0}>
                                        {translate('select')}
                                    </option>
                                    {ERPProducts?.map((ERPProduct, index) => (
                                        <option
                                            key={index}
                                            value={ERPProduct.productNumber}
                                        >
                                            {ERPProduct.name}
                                        </option>
                                    ))}
                                </Select>
                            )}

                            {ERPSetupType ===
                                ExternalPartnerSetup.ErpAlternativeFileCreation && (
                                <Input
                                    value={service?.erpProductId ?? ''}
                                    onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                        const { value } = event.target
                                        handleServiceUpdate({
                                            erpProductId: value,
                                        })
                                    }}
                                />
                            )}
                        </FormControl>
                    </TabPanel>
                    <TabPanel>
                        <FormLabel>{translate('productMapping')}</FormLabel>
                        <SimpleGrid columns={[1, 1, 2, 3, 3]}>
                            {!products && <Spinner />}
                            {products?.map((product, index) => (
                                <Checkbox
                                    isChecked={isCheckboxChecked(product.id)}
                                    key={index}
                                    my={1}
                                    onChange={handleProductUpdate}
                                    value={product.id}
                                >
                                    {product?.name ?? ''}
                                </Checkbox>
                            ))}
                        </SimpleGrid>
                    </TabPanel>
                </TabPanels>
            </Container>
        </PageContainer>
    )
}

const EditServiceSetupPage = (): ReactElement => (
    <ServiceContextProvider>
        <EditServiceSetup />
    </ServiceContextProvider>
)

export default EditServiceSetupPage
