/* eslint-disable no-unsafe-optional-chaining */
import { Fragment, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { NumericFormat } from 'react-number-format'

import { FormControl, FormLabel, Input, Select } from '@chakra-ui/react'

import { useLocationsContext } from '../../../services/contexts/LocationServices.context'
import {
    getProductRuleByType,
    SelectAllOnFocus,
} from '../../../utils/functions.utils'
import { LocalizationConfiguration } from '../../../utils/localization/localization.utils'
import {
    AssetTypeBO,
    LocationDTO,
    ProductRuleDTO,
    ProductRuleType,
} from '../../../utils/types/types'
import { IAssetForm } from '../AssetForm.utils'
import { parseNum } from '../../../utils/localization/culture.utils'

interface IAssetBaseForm extends IAssetForm {
    format: Partial<LocalizationConfiguration>
    actionType: any
    buildErrorList: any
    productRules: ProductRuleDTO[]
    isBaseAsset: boolean
    purchaseValueRules: ProductRuleDTO | undefined
    downpaymentRules: ProductRuleDTO
    downpaymentPercentage: number
    restValueRules: ProductRuleDTO
    restValuePercentage: number
    existingAssetTypes: AssetTypeBO[]
    handlePriceChange: (v: string) => void
    handleDownpaymentChange: (v: string) => void
    handleDownpaymentPercentageChange: (v: string) => void
    handleRestValueChange: (v: string) => void
    handleRestValuePercentageChange: (v: string) => void
}

export default function AssetBaseForm({
    handleInputChange,
    contractAsset,
    actionType,
    buildErrorList,
    productRules,
    format,
    purchaseValueRules,
    downpaymentRules,
    downpaymentPercentage,
    restValueRules,
    restValuePercentage,
    isBaseAsset,
    existingAssetTypes,
    handlePriceChange,
    handleDownpaymentChange,
    handleDownpaymentPercentageChange,
    handleRestValueChange,
    handleRestValuePercentageChange,
}: IAssetBaseForm): ReactElement {
    const { locations } = useLocationsContext()
    const translate = useTranslation().t

    return (
        <>
            <FormControl>
                <FormLabel>{translate('make')}</FormLabel>
                <Input
                    disabled={actionType !== 'add'}
                    type="text"
                    value={contractAsset.make}
                    onChange={(e) => handleInputChange(e.target.value, 'make')}
                />
                {buildErrorList('make')}
            </FormControl>
            <FormControl>
                <FormLabel>{translate('model')}</FormLabel>
                <Input
                    disabled={actionType !== 'add'}
                    type="text"
                    value={contractAsset.model}
                    onChange={(event): void =>
                        handleInputChange(event.target.value, 'model')
                    }
                />
                {buildErrorList('model')}
            </FormControl>
            {!purchaseValueRules?.hidden && !isBaseAsset && (
                <FormControl>
                    <FormLabel>
                        {getProductRuleByType(
                            ProductRuleType.Price,
                            productRules
                        )?.customText || translate('price')}
                    </FormLabel>
                    <NumericFormat
                        onFocus={SelectAllOnFocus}
                        type="text"
                        thousandSeparator={format.thousands}
                        decimalSeparator={format.decimals}
                        customInput={Input}
                        fixedDecimalScale
                        decimalScale={2}
                        isDisabled={
                            purchaseValueRules?.locked || actionType === 'view'
                        }
                        defaultValue={parseNum(
                            purchaseValueRules?.defaultValue
                        )}
                        value={parseNum(contractAsset?.purchaseValue)}
                        onValueChange={(e) => {
                            handlePriceChange(e.value)
                        }}
                    ></NumericFormat>
                    {buildErrorList('purchaseValue')}
                </FormControl>
            )}
            <FormControl>
                <FormLabel>{translate('originalPurchaseValue')}</FormLabel>
                <NumericFormat
                    onFocus={SelectAllOnFocus}
                    type="text"
                    isDisabled={actionType === 'view'}
                    thousandSeparator={format.thousands}
                    decimalSeparator={format.decimals}
                    customInput={Input}
                    fixedDecimalScale
                    decimalScale={2}
                    value={parseNum(contractAsset?.originalPurchaseValue)}
                    onValueChange={(e) => {
                        handleInputChange(e.floatValue, 'originalPurchaseValue')
                    }}
                ></NumericFormat>
            </FormControl>
            {!downpaymentRules?.hidden && !isBaseAsset && (
                <FormControl>
                    <FormLabel>
                        {downpaymentRules?.customText ||
                            translate('downpayment')}
                    </FormLabel>
                    <NumericFormat
                        onFocus={SelectAllOnFocus}
                        type="text"
                        isDisabled={actionType === 'view'}
                        thousandSeparator={format.thousands}
                        decimalSeparator={format.decimals}
                        customInput={Input}
                        fixedDecimalScale
                        decimalScale={2}
                        defaultValue={parseNum(
                            downpaymentRules?.targeted
                                ? 0
                                : downpaymentRules?.defaultValue
                        )}
                        value={parseNum(contractAsset?.downpayment)}
                        onValueChange={(e) => {
                            handleDownpaymentChange(e.value)
                        }}
                    ></NumericFormat>
                    {buildErrorList('downpayment')}
                </FormControl>
            )}
            {!downpaymentRules?.hidden && !isBaseAsset && (
                <FormControl>
                    <FormLabel>
                        {downpaymentRules?.customText
                            ? `${downpaymentRules.customText} %`
                            : translate('downpaymentPercentage')}
                    </FormLabel>
                    <NumericFormat
                        onFocus={SelectAllOnFocus}
                        type="text"
                        isDisabled={actionType === 'view'}
                        thousandSeparator={format.thousands}
                        decimalSeparator={format.decimals}
                        customInput={Input}
                        fixedDecimalScale
                        decimalScale={2}
                        defaultValue={parseNum(
                            downpaymentRules?.targeted
                                ? downpaymentRules?.defaultValue
                                : 0
                        )}
                        value={parseNum(downpaymentPercentage)}
                        onValueChange={(e) => {
                            handleDownpaymentPercentageChange(e.value)
                        }}
                    ></NumericFormat>
                    {buildErrorList('downpaymentPercentage')}
                </FormControl>
            )}
            {!restValueRules?.hidden && !isBaseAsset && (
                <FormControl>
                    <FormLabel>
                        {restValueRules?.customText || translate('restValue')}
                    </FormLabel>
                    <NumericFormat
                        onFocus={SelectAllOnFocus}
                        type="text"
                        thousandSeparator={format.thousands}
                        decimalSeparator={format.decimals}
                        customInput={Input}
                        fixedDecimalScale
                        decimalScale={2}
                        defaultValue={0}
                        value={parseNum(contractAsset?.restValue)}
                        isDisabled={
                            restValueRules?.locked || actionType === 'view'
                        }
                        onValueChange={(e) => {
                            handleRestValueChange(e.value)
                        }}
                    ></NumericFormat>
                    {buildErrorList('restValue')}
                </FormControl>
            )}
            {!restValueRules?.hidden && !isBaseAsset && (
                <FormControl>
                    <FormLabel>
                        {restValueRules?.customText
                            ? `${restValueRules.customText} %`
                            : translate('residualPercentage')}
                    </FormLabel>
                    <NumericFormat
                        onFocus={SelectAllOnFocus}
                        type="text"
                        thousandSeparator={format.thousands}
                        decimalSeparator={format.decimals}
                        customInput={Input}
                        fixedDecimalScale
                        decimalScale={2}
                        defaultValue={0}
                        value={restValuePercentage}
                        min={0}
                        max={100}
                        isDisabled={
                            restValueRules?.locked || actionType === 'view'
                        }
                        onValueChange={(e) => {
                            handleRestValuePercentageChange(e.value)
                        }}
                    ></NumericFormat>
                    {buildErrorList('restValuePercentage')}
                </FormControl>
            )}
            <FormControl>
                <FormLabel>{translate('location')}</FormLabel>
                <Select
                    disabled={actionType !== 'add'}
                    placeholder={translate('select')}
                    value={contractAsset?.location?.locationNumber}
                    onChange={(e) => {
                        handleInputChange(e.target.value, 'locationNumber')
                    }}
                >
                    {locations.map((option: LocationDTO, index: number) => (
                        <option key={index} value={option.locationNumber}>
                            {option.locationName}
                        </option>
                    ))}
                </Select>
            </FormControl>
            <FormControl>
                <FormLabel>{translate('assetType')}</FormLabel>
                <Select
                    disabled={actionType !== 'add'}
                    placeholder={translate('select')}
                    value={
                        (contractAsset as any).assetType?.id ||
                        contractAsset?.assetTypeData?.id
                    }
                    onChange={(e) => {
                        handleInputChange(
                            existingAssetTypes.find(
                                (item) => item.id.toString() === e.target.value
                            ),
                            'assetTypeData'
                        )
                        handleInputChange(+e.target.value, 'assetType')
                    }}
                >
                    {existingAssetTypes.map((item, index) =>
                        item.isActive ? (
                            <option key={index} value={item.id}>
                                {item.name}
                            </option>
                        ) : (
                            <Fragment key={index}></Fragment>
                        )
                    )}
                </Select>
                {buildErrorList('assetType')}
            </FormControl>
            <FormControl>
                <FormLabel>{translate('serialNumber')}</FormLabel>
                <Input
                    type="text"
                    disabled={actionType !== 'add'}
                    value={contractAsset?.serialNumber}
                    onChange={(e: any) =>
                        handleInputChange(e.target.value, 'serialNumber')
                    }
                ></Input>
            </FormControl>
        </>
    )
}
