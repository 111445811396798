import { ReactElement } from 'react'
import { Box } from '@chakra-ui/react'
import {
    CountryCode,
    CustomerDTO,
    InvoiceCommunicationType,
} from '../../utils/types/types'
import DynamicForm from '../dynamicForm/DynamicForm.feature'
import withModalHOC from '../../hoc/modal.hoc'
import { CUSTOMER_FORM } from '../../pages/customerDetails/CustomerDetails.config'
import { useUser } from '../../services/contexts/user.context'
import { deepCopy } from '../../utils/functions.utils'

export const DEFAULT_CUSTOMER_DTO: CustomerDTO = {
    customerNumber: '',
    customerType: 0,
    canBeDeleted: false,
    invoiceCommunication: InvoiceCommunicationType.None,
    country: CountryCode.DK,
}

function AddCustomerContainer(props: any): ReactElement {
    const customer = props?.customer
        ? props.customer
        : deepCopy(DEFAULT_CUSTOMER_DTO)
    const { user } = useUser()

    return (
        <Box>
            <DynamicForm<Partial<CustomerDTO>>
                columns={[1, 1, 2, 3]}
                submitText="saveChanges"
                onSubmit={(data: CustomerDTO): void => {
                    props.handleSaveProduct(data)
                    props.onClose()
                }}
                data={customer}
                formFields={CUSTOMER_FORM(user.culture, props?.readOnly)}
                submitButtonProps={{
                    float: 'right',
                }}
                hideSubmit={props?.readOnly}
            />
        </Box>
    )
}

export default withModalHOC(AddCustomerContainer)
