/* eslint-disable no-plusplus */
/* eslint-disable no-await-in-loop */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactElement, useEffect, useState } from 'react'

import { TabPanel, TabPanels } from '@chakra-ui/react'

import PageContainer, {
    TabListType,
} from '../../../../components/pageContainer/PageContainer.component'
import InvoicesTable from '../../../../features/genericTables/invoicesTable/InvoicesTable.component'
import API_ENDPOINTS from '../../../../services/API/apiEndpoints.constants'
import { generalPaginatedGetAPI } from '../../../../services/API/general.api'
import { useModule } from '../../../../services/contexts/Module.context'
import {
    ExportStatus,
    InvoiceDTO,
    InvoiceEmailStatus,
    InvoiceLineDTO,
    ModuleType,
} from '../../../../utils/types/types'

export const INVOICES_TABS: TabListType[] = [
    {
        id: 0,
        text: 'notSend',
    },
    {
        id: 1,
        text: 'inQueue',
    },
    {
        id: 2,
        text: 'send',
    },
    {
        id: 3,
        text: 'failed',
    },
]
type CombinedInvoiceDTO = InvoiceDTO & InvoiceLineDTO

export default function InvoicesPage(): ReactElement {
    const [invoices, setInvoices] = useState<CombinedInvoiceDTO[]>([])

    const { ensureAccess } = useModule()

    const getInvoices = async (config?: any): Promise<void> => {
        const allInvoices = await generalPaginatedGetAPI(
            (currentPage: string) =>
                `${API_ENDPOINTS.invoice}?pageNumber=${currentPage}&ExportStatus=${ExportStatus.Exported}`,
            config
        )
        if (allInvoices) setInvoices(allInvoices)
    }

    useEffect(() => {
        getInvoices()
    }, [])

    useEffect(() => ensureAccess(ModuleType.ManualInvoicing), [])

    return (
        <PageContainer id="export-page" tabsList={INVOICES_TABS}>
            <TabPanels>
                <TabPanel px={0}>
                    <InvoicesTable
                        invoices={invoices.filter(
                            (elem) =>
                                elem?.isInvoiceEmailSent ===
                                InvoiceEmailStatus.NotSend
                        )}
                        hasSendEmail={false}
                    />
                </TabPanel>
                <TabPanel px={0}>
                    <InvoicesTable invoices={invoices} />
                </TabPanel>
                <TabPanel px={0}>
                    <InvoicesTable
                        invoices={invoices.filter(
                            (elem) =>
                                elem?.isInvoiceEmailSent ===
                                InvoiceEmailStatus.Send
                        )}
                    />
                </TabPanel>
                <TabPanel px={0}>
                    <InvoicesTable
                        invoices={invoices.filter(
                            (elem) =>
                                elem?.isInvoiceEmailSent ===
                                InvoiceEmailStatus.Failed
                        )}
                    />
                </TabPanel>
            </TabPanels>
        </PageContainer>
    )
}
