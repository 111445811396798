import {
    createContext,
    ReactElement,
    ReactNode,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react'
import { useTranslation } from 'react-i18next'

import { useToast } from '@chakra-ui/react'

import { RefreshPenneoStatus } from '../../pages/contractDetail/components/documents/ContractDetailDocument.config'
import {
    baseErrorToastOptions,
    baseSuccessToastOptions,
} from '../../utils/functions.utils'
import {
    ForeignSignatureSystem,
    SignatureDigitalType,
    SignatureReceiver,
    SignerDto,
} from '../../utils/types/types'
import API_ENDPOINTS from '../API/apiEndpoints.constants'
import {
    generalDeleteAPI,
    generalGetAPI,
    generalPostAPI,
    generalPutAPI,
} from '../API/general.api'

// TODO: This provider is overkill for what we need, could be implemented directly on the component it needs it (only 1)

interface SignersContextInterface {
    signersList: SignerDto[]
    signerFormItem: SignerDto
    isSignersLoading: boolean
    isSignersModalOpen: boolean
    isSignersFormOpen: boolean
    selectedSigners: SignerDto[]
    isSentToSignersLoading: boolean
    signerDocumentStatuses: RefreshPenneoStatus[]
    signatureSystem: ForeignSignatureSystem | null
    loadInitialSignersData: Function
    closeModal: () => void
    openModal: Function
    handleSignerChangeRequest: Function
    handleSignerSave: Function
    handleSignerChangeCancel(): void
    closeSignersForm: Function
    handleSignerDeleteRequest(signer: SignerDto): Promise<void>
    handleSignerSelection(data: SignerDto[], actions?: any): void
    handleSignersSentRequest(): void
    handleSignersDocumentStatuses(signerContractNumber: string): void
}

export const SignersContext = createContext({} as SignersContextInterface)

export const SignersContextProvider = ({
    children,
}: {
    children: ReactNode
}): ReactElement => {
    const [signersList, setSignersList] = useState<SignerDto[]>([])
    const [signerFormItem, setSignerFormItem] = useState<SignerDto>(
        {} as SignerDto
    )
    const [isSignersLoading, setRequestLoading] = useState<boolean>(true)
    const [isSignersModalOpen, setSignersModalOpen] = useState<boolean>(false)
    const [isSignersFormOpen, setSignersFormOpen] = useState<boolean>(false)
    const [signerCustomerNumber, setSignerCustomerNumber] = useState<string>('')
    const [signerContractNumber, setSignerContractNumber] = useState<string>('')
    const [selectedSigners, setSelectedSigners] = useState<SignerDto[]>([])
    const [signerDocumentID, setSignerDocumentID] = useState<number>(0)
    const [isSentToSignersLoading, setSentToSignersLoading] =
        useState<boolean>(false)
    const [signerDocumentStatuses, setSignerDocumentStatuses] = useState<
        RefreshPenneoStatus[]
    >([])
    const [signatureSystem, setSignatureSystem] =
        useState<ForeignSignatureSystem | null>(null)

    useEffect(() => {
        retrieveForeignSignerSystem()
    }, [])

    const translate = useTranslation().t
    const toast = useToast()
    const resetSelectedSignerItems: any = useRef()

    const loadInitialSignersData = async (
        customerNumber: string,
        contractNumber?: string,
        documentId?: string | number
    ): Promise<void> => {
        setSignerCustomerNumber(customerNumber)
        if (contractNumber) setSignerContractNumber(contractNumber)
        if (documentId) setSignerDocumentID(parseInt(documentId as string))
        setSelectedSigners([])
        let requestUrl = `${API_ENDPOINTS.signers}/${customerNumber}/customer`
        if (contractNumber) {
            requestUrl = `${API_ENDPOINTS.signers}?customerNumber=${customerNumber}&contractNumber=${contractNumber}`
        }
        setRequestLoading(true)
        const request = await generalGetAPI(requestUrl)
        setRequestLoading(false)
        if (!request.isOk) {
            toast(baseErrorToastOptions(request.err)) as string
            return
        }
        setSignersList(request.data)
    }

    const handleSignerChangeRequest = (signer: SignerDto): void => {
        setSignerFormItem({ ...signer })
        setSignersFormOpen(true)
    }

    const closeSignersForm = (): void => setSignersFormOpen(false)

    const handleSignerSave = async (signer: SignerDto): Promise<void> => {
        setRequestLoading(true)
        setSignersFormOpen(false)
        if (signer.id) {
            const request = await generalPutAPI(
                `${API_ENDPOINTS.signers}/${signer.id}`,
                { ...signer }
            )
            if (!request.isOk) {
                toast(baseErrorToastOptions(request.message))
            }
        } else {
            let requestUrl = `${API_ENDPOINTS.signers}/${signerCustomerNumber}/customer`
            if (signerContractNumber) {
                requestUrl = `${API_ENDPOINTS.signers}/${signerContractNumber}/contract`
            }
            const request = await generalPostAPI(requestUrl, { ...signer })
            if (!request.isOk) {
                toast(baseErrorToastOptions(request.message))
            }
        }
        loadInitialSignersData(signerCustomerNumber, signerContractNumber)
        handleSignerSelectionReset()
    }

    const retrieveForeignSignerSystem = async (): Promise<void> => {
        if (signatureSystem !== null) return

        const request = await generalGetAPI(API_ENDPOINTS.setupActive)
        if (!request.isOk) return

        const activeDocumentSetup = request.data.find(
            (item: any) => item.externalSystemType === 'Document Signing System'
        )

        const { setupType } = activeDocumentSetup

        if (setupType === 'Scrive') {
            setSignatureSystem(ForeignSignatureSystem.Scrive)
        } else {
            setSignatureSystem(ForeignSignatureSystem.Penneo)
        }
    }

    const handleSignerChangeCancel = (): void => {
        setSignerFormItem({} as SignerDto)
        setSignersFormOpen(false)
    }

    const handleSignerDeleteRequest = async (
        signer: SignerDto
    ): Promise<void> => {
        setRequestLoading(true)
        setSignersFormOpen(false)
        const request = await generalDeleteAPI(
            `${API_ENDPOINTS.signers}/${signer.id}`
        )
        if (!request.isOk) {
            toast(baseErrorToastOptions(request.message))
        }
        loadInitialSignersData(signerCustomerNumber, signerContractNumber)
        handleSignerSelectionReset()
    }

    const handleSignerSelection = (data: SignerDto[], actions: any): void => {
        setSelectedSigners([...data])
        resetSelectedSignerItems.current = actions.resetSelectedItems
    }

    const handleSignerSelectionReset = () => {
        setSelectedSigners([])
        if (resetSelectedSignerItems.current) {
            resetSelectedSignerItems.current()
        }
    }

    const closeModal = (): void => {
        if (isSignersModalOpen) {
            setSignersModalOpen(false)
        }
    }

    const openModal = (): void => {
        if (!isSignersModalOpen) {
            setSignersModalOpen(true)
        }
    }

    const handleSignersSentRequest = async (): Promise<void> => {
        if (selectedSigners.length === 0) return
        const signersWithTouch = selectedSigners.map((selectedSigner) => {
            selectedSigner.enableTouchSignature =
                selectedSigner.digitalType === SignatureDigitalType.Touch
            return selectedSigner
        })
        const signersRequestObject = {
            documentId: signerDocumentID,
            signers: signersWithTouch.filter(
                (selectedSigner) =>
                    selectedSigner.receiver !== SignatureReceiver.Copy
            ),
            carbonCopy: signersWithTouch.filter(
                (selectedSigner) =>
                    selectedSigner.receiver === SignatureReceiver.Copy
            ),
        }

        // Validations
        // Force user have at least 1 signer
        if (
            !signersRequestObject.signers ||
            signersRequestObject.signers.length === 0
        ) {
            toast(baseErrorToastOptions(translate('signerRequiredRule')))
            return
        }
        // If ForeignSignatureSystem is scrive we need to force users to assign orders
        if (signatureSystem === ForeignSignatureSystem.Scrive) {
            const hasIncorrectOrder = signersRequestObject.signers.some(
                (signer) => !signer?.signOrder || signer.signOrder <= 0
            )
            if (hasIncorrectOrder) {
                toast(
                    baseErrorToastOptions(translate('signOrderSpecifiedRule'))
                )
                return
            }
        }

        // If ForeignSignatureSystem is Scrive we need to remove unnecessary keys from carbonCopy
        if (signatureSystem === ForeignSignatureSystem.Scrive) {
            const fieldToKeep = ['name', 'email', 'signOrder']
            signersRequestObject.carbonCopy =
                signersRequestObject.carbonCopy.map((copy) => {
                    const data = Object.fromEntries(
                        Object.entries(copy).filter(([key]) =>
                            fieldToKeep.includes(key)
                        )
                    )
                    return data as SignerDto
                })
        }

        setSentToSignersLoading(true)
        const requestUrl =
            signatureSystem === ForeignSignatureSystem.Penneo
                ? API_ENDPOINTS.penneoSign
                : API_ENDPOINTS.scriveSign
        const request = await generalPostAPI(requestUrl, signersRequestObject)
        setSentToSignersLoading(false)
        if (!request.isOk) {
            toast(baseErrorToastOptions(request.message))
            return
        }
        toast(baseSuccessToastOptions(translate('penneoCompleted')))
        setSelectedSigners([])
        if (signatureSystem === ForeignSignatureSystem.Penneo) {
            handleSignersDocumentStatuses(signerContractNumber)
        }
        closeModal()
    }

    const handleSignersDocumentStatuses = async (
        signContractNumber: string
    ) => {
        const request = await generalPostAPI(API_ENDPOINTS.penneoStatus, {
            contractNumber: signContractNumber,
        })
        if (request.isOk) {
            setSignerDocumentStatuses(request.data)
        }
    }

    return (
        <SignersContext.Provider
            value={{
                isSignersLoading,
                isSignersModalOpen,
                isSignersFormOpen,
                signersList,
                signerFormItem,
                selectedSigners,
                isSentToSignersLoading,
                signerDocumentStatuses,
                signatureSystem,
                loadInitialSignersData,
                closeModal,
                closeSignersForm,
                openModal,
                handleSignerChangeRequest,
                handleSignerSave,
                handleSignerChangeCancel,
                handleSignerDeleteRequest,
                handleSignerSelection,
                handleSignersSentRequest,
                handleSignersDocumentStatuses,
            }}
        >
            {children}
        </SignersContext.Provider>
    )
}

export const useSigners = (): SignersContextInterface =>
    useContext(SignersContext)
