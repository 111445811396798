import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Stack } from '@chakra-ui/react'

import withModalHOC, { ModalSharedProps } from '../../../hoc/modal.hoc'
import DynamicGrid from '../../../pages/demo/DynamicAGGrid.component'
import { useNumberFormatterContext } from '../../../services/contexts/NumberFormatter.context'
import { InvoiceLineDTO } from '../../../utils/types/types'
import { CustomerDetailInvoiceLineConfig } from './InvoiceLinesTable.config'

interface IInvoiceLinesTable {
    invoiceLines?: InvoiceLineDTO[]
}

interface IInvoiceLinesTableModal
    extends IInvoiceLinesTable,
        ModalSharedProps {}

export default function InvoiceLinesTable({
    invoiceLines,
}: IInvoiceLinesTable): ReactElement {
    const [data, setData] = useState<InvoiceLineDTO[]>([])
    const translate = useTranslation().t
    const { formatValue } = useNumberFormatterContext()

    useEffect(() => {
        invoiceLines && setData(invoiceLines)
    }, [invoiceLines])

    return (
        <>
            <Stack spacing={4}>
                <DynamicGrid
                    tableId={'invoiceLinesTable'}
                    columns={CustomerDetailInvoiceLineConfig(
                        translate,
                        formatValue
                    )}
                    rowData={data}
                />
            </Stack>
        </>
    )
}

export const InvoiceLinesTableModal =
    withModalHOC<IInvoiceLinesTableModal>(InvoiceLinesTable)
