/* eslint-disable @typescript-eslint/no-shadow */
import { generalGetAPI } from '../../../services/API/general.api'
import {
    baseErrorToastOptions,
    buildListOfKeyValues,
    standaloneToast,
} from '../../../utils/functions.utils'
import {
    ERPParametersDTO,
    ExternalPartnerSetup,
    InvoiceLineType,
    ParameterDTO,
    UpdateExternalPartnerSystemSetupCommand,
} from '../../../utils/types/types'

export const GroupInvoiceLinesByMappingKey = 'groupInvoiceLinesByMapping'

export enum AvailablePartnerSetup {
    EconomicsErp = 100,
    BusinessCentralErp = 101,
    ManualCSV = 102,
    Fortnox = 103,
    Uniconta = 104,
    VismaEAccounting = 105,
    Netvisor = 106,
}

export interface ERPSetupProduct {
    name: string
    productNumber: string
}

export interface IERPAccountsComponent {
    product: ERPParametersDTO
    activeSetup: ExternalPartnerSetup
    ERPSetup: UpdateExternalPartnerSystemSetupCommand
    setInvoiceLines: Function
}

export const loadProperties = async (
    endPoint: string,
    ERPType?: ExternalPartnerSetup
): Promise<any> => {
    let url = endPoint
    if (ERPType) {
        url = `${endPoint}/${ERPType}`
    }
    const response = await generalGetAPI(url)
    if (!response.isOk) standaloneToast(baseErrorToastOptions(response.message))
    return response.data
}

export const getGroupInvoiceLinesByMappingValue = (
    productId: number,
    groupInvoiceLinesByMapping: ParameterDTO[] | undefined
): boolean => {
    const group = groupInvoiceLinesByMapping?.find(
        (group) => group.productId === productId
    )
    return group?.value === 'true' ?? false
}

export const buildLineProductPairs = (productId: number) => {
    const values = buildListOfKeyValues(InvoiceLineType).map((elem) => elem[0])
    const lineProductPairs = values?.map((value) => ({
        lineType: value,
        erpProductId: `${productId}`,
    }))

    return lineProductPairs
}

export const updateERPSetupsWithProductData = (
    erpSetups: ERPParametersDTO[],
    product: ERPParametersDTO
): any[] => {
    const index = erpSetups.findIndex(
        (item) => item.productId === product.productId
    )
    if (index !== -1) {
        return erpSetups.map((setup) => {
            if (setup.productId === product.productId) {
                setup.defaultCustomerGroup =
                    product.defaultCustomerGroup ?? setup.defaultCustomerGroup
                setup.defaultLayout =
                    product.defaultLayout ?? setup.defaultLayout
                setup.defaultPaymentTerm =
                    product.defaultPaymentTerm ?? setup.defaultPaymentTerm
            }
            return setup
        })
    }
    erpSetups.push(product)
    return erpSetups
}
