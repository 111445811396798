import { ReactElement, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { ViewIcon } from '@chakra-ui/icons'
import {
    Button,
    Flex,
    IconButton,
    Stack,
    Tooltip,
    useBoolean,
    useToast,
} from '@chakra-ui/react'

import ExportedQueueInvoiceLines from '../../../../../../app/config/download/exportedQueueInvoiceLines.config.json'
import DownloadActions from '../../../../../../components/downloadActions/DownloadActions.component'
import { InvoiceLinesTableModal } from '../../../../../../features/genericTables/invoiceLinesTable/InvoiceLinesTable.component'
import API_ENDPOINTS from '../../../../../../services/API/apiEndpoints.constants'
import {
    generalGetAPI,
    generalPostAPI,
} from '../../../../../../services/API/general.api'
import { useNumberFormatterContext } from '../../../../../../services/contexts/NumberFormatter.context'
import {
    ColumnActionConfiguration,
    baseErrorToastOptions,
    baseSuccessToastOptions,
} from '../../../../../../utils/functions.utils'
import {
    ExportStatus,
    ExternalPartnerSetup,
    InvoiceDTO,
    InvoiceLineDTO,
} from '../../../../../../utils/types/types'
import DynamicGrid from '../../../../../demo/DynamicAGGrid.component'
import { EXPORT_HEADERS } from '../../Export.config'
import { useERP } from '../../../../../../services/erp/ERP.services'

export default function ExportQueuePage({
    showInvoiceLinesPopup = true,
}): ReactElement {
    const [selectedItems, setSelectedItems] = useState<InvoiceDTO[]>([])
    const { formatValue } = useNumberFormatterContext()
    const translate = useTranslation().t
    const toast = useToast()
    const [data, setData] = useState<InvoiceDTO[]>()
    const [isDownloadEnabled, setDownloadEnabled] = useState(false)
    const [isSendEmailEnabled] = useState(true)
    const [invoiceLines, setInvoiceLines] = useState<InvoiceLineDTO[]>([])
    const [isInvoiceLinesOpen, setInvoiceLinesOpen] = useBoolean()
    const navigate = useNavigate()
    const { activeSetup } = useERP()
    const isManualCSV = useMemo(
        () => activeSetup === ExternalPartnerSetup.ErpAlternativeFileCreation,
        []
    )

    const getData = () => {
        generalGetAPI(
            `${API_ENDPOINTS.invoiceExport}?ExportStatus=${ExportStatus.Initialized}`
        ).then((response) => {
            response.isOk && setData(response.data.items)
        })
    }

    useEffect(() => getData(), [])

    const sendInvoicesEmail = (invoices: InvoiceDTO[]) => {
        generalPostAPI(API_ENDPOINTS.invoiceActionsSend, {
            invoiceNumbers: invoices.map((i) => i.invoiceNumber),
        }).then((response) => {
            const failedInvoices = response.data?.failedInvoices
            if (response.isOk && failedInvoices.length > 0) {
                toast(baseErrorToastOptions(translate('invoice sent failed')))
            } else if (response.isOk) {
                toast(
                    baseSuccessToastOptions(translate('invoice sent success'))
                )
            } else toast(baseErrorToastOptions(response.message))
        })
    }

    const selectAllElements = async (): Promise<void> => {
        data && setSelectedItems([...data])
    }

    const fields = EXPORT_HEADERS(
        translate,
        formatValue,
        false,
        isDownloadEnabled || isSendEmailEnabled
    )

    return (
        <>
            <Stack spacing={4}>
                <DynamicGrid
                    tableId="exportedQueueTable"
                    onSelectionChanged={(values: any): void => {
                        const selectedNodes = values.api.getSelectedNodes()
                        const selectedData = selectedNodes.map(
                            (node: any) => node.data
                        )
                        setSelectedItems(selectedData)
                    }}
                    headers={
                        <>
                            <Flex
                                justifyContent={'flex-end'}
                                alignItems="center"
                                gap={2}
                            >
                                {isManualCSV && (
                                    <Button
                                        isDisabled={!selectedItems?.length}
                                        onClick={() => {
                                            sendInvoicesEmail(selectedItems)
                                            setSelectedItems([])
                                        }}
                                    >
                                        {translate('send email')}{' '}
                                        {!!selectedItems?.length &&
                                            `(${selectedItems.length})`}
                                    </Button>
                                )}
                                <DownloadActions
                                    isDownloadEnabled={isDownloadEnabled}
                                    setDownloadEnabled={setDownloadEnabled}
                                    selectAllElements={selectAllElements}
                                    selectedItems={selectedItems}
                                    downloadConfig={ExportedQueueInvoiceLines}
                                    resetSelectedItems={() =>
                                        setSelectedItems([])
                                    }
                                />
                            </Flex>
                        </>
                    }
                    columns={[
                        ...fields,
                        ColumnActionConfiguration(translate, (params: any) => (
                            <Tooltip
                                label={translate('view')}
                                placement="top"
                                hasArrow
                            >
                                <IconButton
                                    variant={'outline'}
                                    size="sm"
                                    aria-label="View"
                                    onClick={(): void =>
                                        showInvoiceLinesPopup
                                            ? (setInvoiceLines(
                                                  params?.data?.lines
                                              ),
                                              setInvoiceLinesOpen.on())
                                            : navigate(
                                                  `/financial/invoices/${params.data.invoiceNumber}`,
                                                  {
                                                      state: params.data,
                                                  }
                                              )
                                    }
                                    icon={<ViewIcon />}
                                />
                            </Tooltip>
                        )),
                    ]}
                    rowData={data}
                />
            </Stack>
            {isInvoiceLinesOpen && showInvoiceLinesPopup && (
                <InvoiceLinesTableModal
                    invoiceLines={invoiceLines}
                    isModalOpen={isInvoiceLinesOpen}
                    modalTitle={translate('invoiceLines')}
                    onClose={(): void => {
                        setInvoiceLinesOpen.off()
                    }}
                />
            )}
        </>
    )
}
