import {
    createContext,
    ReactElement,
    ReactNode,
    useContext,
    useEffect,
    useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { numberToColor } from '../../utils/color.utils'
import { PartnerDetailsDTO, PartnerDTO } from '../../utils/types/types'
import API_ENDPOINTS from '../API/apiEndpoints.constants'
import {
    generalFilePostAPI,
    generalGetAPI,
    generalPutAPI,
} from '../API/general.api'
import { useColorContext } from '../hooks/color.hook'
import { useUser } from './user.context'

interface IPartnerContext {
    activePartner?: PartnerDTO
    partnerDetails?: PartnerDetailsDTO
    partners: Array<PartnerDTO>
    changePartner: (newPartnerId: string | number) => Promise<void>
    getPartnerDetails: () => Promise<void>
    updatePartnerDetails: (partner: PartnerDetailsDTO) => Promise<void>
    updatePartnerLogo: (logo: any) => Promise<void>
    updateDocumentPartnerLogo: (logo: any) => Promise<void>
}

interface IPartnerContextProviderProps {
    children: ReactNode
}

const partnerContext = createContext({} as IPartnerContext)

export const usePartner = () => useContext(partnerContext)

const useProviderPartner = () => {
    const { user } = useUser()
    const translate = useTranslation().t
    const [activePartner, setActivePartner] = useState<PartnerDTO>()
    const [partnerDetails, setPartnerDetails] = useState<PartnerDetailsDTO>()
    const [partners, setPartners] = useState<any>()
    const { definePrimary, defineSecondary } = useColorContext()

    useEffect(() => {
        let isMounted = true

        const getActivePartner = async (): Promise<void> => {
            const response = await generalGetAPI(API_ENDPOINTS.partnerActive)
            if (isMounted && response.isOk) setActivePartner(response.data)
            else toast.error(response.message)
        }

        const getPartners = async (): Promise<void> => {
            const response = await generalGetAPI(
                API_ENDPOINTS.partnerCurrentUser
            )
            if (isMounted && response.isOk) setPartners(response.data)
            else toast.error(response.message)
        }
        if (user?.email) {
            getActivePartner()
            getPartners()
        }

        return (): void => {
            isMounted = false
        }
    }, [user])

    useEffect(() => {
        getPartnerDetails()
    }, [activePartner])

    useEffect(() => {
        partnerDetails?.lightModeColor &&
            definePrimary(numberToColor(partnerDetails?.lightModeColor))
        partnerDetails?.darkModeColor &&
            defineSecondary(numberToColor(partnerDetails?.darkModeColor))
    }, [partnerDetails])

    const changePartner = async (
        newPartnerId: string | number
    ): Promise<void> => {
        const updateResponse = await generalPutAPI(API_ENDPOINTS.userPartner, {
            newPartnerId,
        })
        if (!updateResponse.isOk) return

        if (updateResponse.isOk) {
            const response = await generalGetAPI(API_ENDPOINTS.partnerActive)
            if (response.isOk) {
                setActivePartner(response.data)
            } else {
                toast.error(response.message)
            }
        } else {
            toast.error(updateResponse.err)
        }
    }

    const getPartnerDetails = async (): Promise<void> => {
        const response = await generalGetAPI(API_ENDPOINTS.partnerDetails)
        if (response?.isOk) {
            setPartnerDetails(response.data)
        }
    }

    const updatePartnerDetails = async (
        details: PartnerDetailsDTO
    ): Promise<void> => {
        const response = await generalPutAPI(
            API_ENDPOINTS.partnerDetails,
            details
        )
        if (response.isOk) {
            toast.success(translate('partnerUpdated') as string)
            getPartnerDetails()
        } else {
            toast.error(response.err)
        }
    }

    // TODO: Upddate with partnerLogo logic
    const updatePartnerLogo = async (formData: FormData): Promise<void> => {
        const response = await generalFilePostAPI(
            API_ENDPOINTS.partnerLogoUI,
            formData
        )
        if (response.isOk) {
            toast.success(translate('partnerLogoUpdated') as string)
            setPartnerDetails(response.data)
        } else {
            toast.error(response.err)
        }
    }

    const updateDocumentPartnerLogo = async (
        formData: FormData
    ): Promise<void> => {
        const response = await generalFilePostAPI(
            API_ENDPOINTS.partnerLogoGeneral,
            formData
        )
        if (response.isOk) {
            toast.success(translate('partnerLogoUpdated') as string)
            setPartnerDetails(response.data)
        } else {
            toast.error(response.err)
        }
    }

    return {
        activePartner,
        partnerDetails,
        partners,
        changePartner,
        getPartnerDetails,
        updatePartnerDetails,
        updatePartnerLogo,
        updateDocumentPartnerLogo,
    }
}

export const PartnerContextProvider = ({
    children,
}: IPartnerContextProviderProps): ReactElement => {
    const partnerValues: IPartnerContext = useProviderPartner()

    return (
        <partnerContext.Provider value={partnerValues}>
            {children}
        </partnerContext.Provider>
    )
}
