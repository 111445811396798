/* eslint-disable no-unused-vars */
import orderBy from 'lodash/orderBy'
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactElement, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { Button, Flex, grid, useBoolean, useToast } from '@chakra-ui/react'

import InventoryDownloadConfig from '../../app/config/download/inventory.config.json'
import DownloadActions from '../../components/downloadActions/DownloadActions.component'
import PageContainer from '../../components/pageContainer/PageContainer.component'
import { AddAssetModal } from '../../features/addAsset/addAsset.feature'
import API_ENDPOINTS from '../../services/API/apiEndpoints.constants'
import { generalPostAPI } from '../../services/API/general.api'
import { useLoading } from '../../services/contexts/Loading.context'
import { DEFAULT_ASSET } from '../../utils/constants.utils'
import {
    baseErrorToastOptions,
    baseSuccessToastOptions,
} from '../../utils/functions.utils'
import { getAllPagesHandlerPrompt } from '../../utils/pagination/pagination.util'
import { GetBaseAssetDetail } from '../../utils/types/types'
import DynamicGrid from '../demo/DynamicAGGrid.component'
import { columnsConfig } from './Inventory.config'

function InventoryPage(): ReactElement {
    const gridApiRef = useRef(null)
    const { globalLoading, stopGlobalLoading } = useLoading()
    const [isAddAssetOpen, setAddAsset] = useBoolean()
    const [isDownloadEnabled, setDownloadEnabled] = useState(false)
    const [selectedItems, setSelectedItems] = useState<GetBaseAssetDetail[]>([])
    const translate = useTranslation().t
    const navigate = useNavigate()
    const toast = useToast()
    const [searchParams] = useSearchParams()
    const [inventoryList, setInventoryList] = useState<GetBaseAssetDetail[]>()
    const [filteredInventoryList, setFilteredInventoryList] =
        useState<GetBaseAssetDetail[]>()

    const handleCreateAsset = async (asset: any): Promise<void> => {
        const loadingID = globalLoading()
        setAddAsset.off()

        try {
            const response = await generalPostAPI(
                API_ENDPOINTS.assetBase,
                asset
            )
            if (response.isOk) {
                toast(baseSuccessToastOptions(translate('assetCreated')))
                getInventory()
            } else {
                toast(baseErrorToastOptions(response.message))
            }
        } catch (error) {
            console.error(error)
        } finally {
            stopGlobalLoading(loadingID)
        }
    }

    const getInventory = async (): Promise<void> => {
        getAllPagesHandlerPrompt(
            API_ENDPOINTS.assetBasePaginated,
            (result: any) => {
                const orderedResult = orderBy(result, 'baseAssetNumber', 'desc')
                setInventoryList([...orderedResult])
                setFilteredInventoryList([...orderedResult])
            },
            { includeVirtualAssets: true }
        )
    }

    useEffect(() => {
        getInventory()
    }, [])

    useEffect(() => {
        let inventory = inventoryList
        const status = searchParams.get('status')
        if (status && status !== 'all') {
            inventory = inventoryList?.filter(
                (c: GetBaseAssetDetail) => c.assetStatus.toString() === status
            )
        }
        inventory && setFilteredInventoryList([...inventory])
    }, [searchParams?.get('status'), JSON.stringify(inventoryList)])

    const selectAllElements = async (): Promise<void> => {
        inventoryList && setSelectedItems([...inventoryList])
    }

    const onGridReady = (params: any): void => {
        gridApiRef.current = params.api
    }

    const getSelectedData = (): void => {
        const selectedNodes = (gridApiRef?.current as any)?.getSelectedNodes()
        const selectedData = selectedNodes.map((node: any) => node.data)
        setSelectedItems(selectedData)
    }

    const onResetSelectedItems = (): void => {
        (gridApiRef?.current as any)?.deselectAll()
        setSelectedItems([])
    }

    return (
        <PageContainer>
            <DynamicGrid
                tableId="inventoryPageTable"
                onGridReady={onGridReady}
                columns={columnsConfig(translate, navigate, isDownloadEnabled)}
                rowData={filteredInventoryList}
                rowSelection={'multiple'}
                pagination={true}
                rowMultiSelectWithClick={false}
                onSelectionChanged={(): void => {
                    getSelectedData()
                }}
                headers={
                    <Flex
                        justifyContent={'flex-end'}
                        w={'100%'}
                        mb={4}
                        gap={2}
                        alignItems={'center'}
                    >
                        <DownloadActions
                            isDownloadEnabled={isDownloadEnabled}
                            setDownloadEnabled={setDownloadEnabled}
                            selectAllElements={selectAllElements}
                            selectedItems={selectedItems}
                            downloadConfig={InventoryDownloadConfig}
                            resetSelectedItems={onResetSelectedItems}
                        />
                        <Button onClick={(): void => setAddAsset.on()}>
                            {translate('create')}
                        </Button>
                    </Flex>
                }
            />

            {isAddAssetOpen && (
                <AddAssetModal
                    isBaseAsset={true}
                    modalTitle={translate('asset')}
                    isModalOpen={isAddAssetOpen}
                    onClose={setAddAsset.off}
                    assetItem={{
                        ...DEFAULT_ASSET,
                    }}
                    actionType={'add'}
                    handleSaveAsset={handleCreateAsset}
                />
            )}
        </PageContainer>
    )
}

const InventoryPageWithActions = () => <InventoryPage />
export default InventoryPageWithActions
