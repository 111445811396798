import '../../services/i18n.service'

import Cookies from 'js-cookie'
import { ReactElement, useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'

import { chakra, ChakraProvider } from '@chakra-ui/react'
import { createStandaloneToast } from '@chakra-ui/toast'

import Auth0ProviderContainer from '../../Auth0ProviderContainer'
import GlobalLoader from '../../components/globalLoader/GlobalLoader.component'
import { ChecklistInteractionContextProvider } from '../../services/checklistInteractions/ChecklistInteraction.service'
import { LoadingContextProvider } from '../../services/contexts/Loading.context'
import { LocationsContextProvider } from '../../services/contexts/LocationServices.context'
import { ModuleContextProvider } from '../../services/contexts/Module.context'
import { NumberFormatterProvider } from '../../services/contexts/NumberFormatter.context'
import { PartnerContextProvider } from '../../services/contexts/partner.context'
import PenneoContextProvider from '../../services/contexts/penneo.context'
import { ProductContextProvider } from '../../services/contexts/Product.context'
import { UserContextProvider } from '../../services/contexts/user.context'
import { ContractServiceProvider } from '../../services/contract/Contract.services'
import {
    ColorsContextProvider,
    useColorContext,
} from '../../services/hooks/color.hook'
import ABSTRACT_THEME from '../../theme/theme'
import { useScreenSizeClass } from '../../utils/media-query'
import AppContent from './app-content.component'
import { ERPContextProvider } from '../../services/erp/ERP.services'

export default function Root(): ReactElement {
    const { ToastContainer } = createStandaloneToast()
    const screenSizeClass = useScreenSizeClass()

    return (
        <BrowserRouter>
            <ColorsContextProvider>
                <LoadingContextProvider>
                    <Auth0ProviderContainer>
                        <UserContextProvider>
                            <NumberFormatterProvider>
                                <PenneoContextProvider>
                                    <ProductContextProvider>
                                        <PartnerContextProvider>
                                            <ContractServiceProvider>
                                                <ChakraWrapper>
                                                    <ERPContextProvider>
                                                        <LocationsContextProvider>
                                                            <ModuleContextProvider>
                                                                <ChecklistInteractionContextProvider>
                                                                    <chakra.div
                                                                        overflow={
                                                                            'hidden'
                                                                        }
                                                                        height={
                                                                            '100%'
                                                                        }
                                                                        className={`app ${screenSizeClass}`}
                                                                    >
                                                                        <AppContent />
                                                                        <GlobalLoader />
                                                                    </chakra.div>
                                                                </ChecklistInteractionContextProvider>
                                                            </ModuleContextProvider>
                                                            <ToastContainer />
                                                        </LocationsContextProvider>
                                                    </ERPContextProvider>
                                                </ChakraWrapper>
                                            </ContractServiceProvider>
                                        </PartnerContextProvider>
                                    </ProductContextProvider>
                                </PenneoContextProvider>
                            </NumberFormatterProvider>
                        </UserContextProvider>
                    </Auth0ProviderContainer>
                </LoadingContextProvider>
            </ColorsContextProvider>
        </BrowserRouter>
    )
}

function ChakraWrapper({ children }: any): ReactElement {
    const { primary, definePrimary, secondary, defineSecondary } =
        useColorContext()

    useEffect(() => {
        const color1 = Cookies.get('primary')
        const color2 = Cookies.get('secondary')
        if (color1) definePrimary?.(color1)
        if (color2) defineSecondary?.(color2)
    }, [])
    return (
        <ChakraProvider
            theme={ABSTRACT_THEME(primary, secondary)}
            cssVarsRoot="body"
        >
            {children}
        </ChakraProvider>
    )
}
