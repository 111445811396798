import { AgChartsReact } from 'ag-charts-react'
import camelCase from 'lodash/camelCase'
import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useToast } from '@chakra-ui/react'

import API_ENDPOINTS from '../../../services/API/apiEndpoints.constants'
import { generalGetAPI } from '../../../services/API/general.api'
import { baseSuccessToastOptions } from '../../../utils/functions.utils'
import {
    ContractsPerStatusDto,
    ContractStatus,
} from '../../../utils/types/types'

const ContractsPerStatusChart = (): ReactElement => {
    const translate = useTranslation().t
    const toast = useToast()
    const [contractsPerStatus, setContractsPerStatus] = useState<
        ContractsPerStatusDto[]
    >([])

    const getContractsPerStatus = async (): Promise<void> => {
        try {
            const response: any = await generalGetAPI(
                API_ENDPOINTS.dashboardContractsPerStatus
            )
            if (response?.data && response?.isOk) {
                setContractsPerStatus(response.data)
            }
            if (!response.isOk) {
                toast(baseSuccessToastOptions(response.message))
            }
        } catch (error: any) {
            toast(baseSuccessToastOptions(error?.message))
        }
    }

    useEffect(() => {
        getContractsPerStatus()
    }, [])

    const createChartData = (): any[] =>
        // Convert your DTO data into the format expected by AG-Grid charts
        contractsPerStatus.map((item) => ({
            status: translate(camelCase(ContractStatus[item?.status])), // Ensure it's a string for categorical axis
            numberOfContracts: item.numberOfContracts,
        }))

    const createOptions = (data: any[]): any => ({
        data,
        height: 500,
        title: {
            text: translate('contractsPerStatus'),
        },
        series: [
            {
                type: 'donut',
                angleKey: 'numberOfContracts',
                calloutLabelKey: 'status',
                title: {
                    text: translate('totalContracts'),
                },
                innerRadiusRatio: 0.7,
                innerLabels: [
                    {
                        text: translate('total'),
                        fontSize: 16,
                        margin: 10,
                    },
                    {
                        text: data
                            .reduce((sum, d) => sum + d.numberOfContracts, 0)
                            .toString(),
                        fontSize: 24,
                    },
                ],
                strokeWidth: 3,
            },
        ],
    })

    return <AgChartsReact options={createOptions(createChartData())} />
}

export default ContractsPerStatusChart
