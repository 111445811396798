import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import API_ENDPOINTS from '../../../../../services/API/apiEndpoints.constants'
import { getAllPagesHandlerPrompt } from '../../../../../utils/pagination/pagination.util'
import { DocumentType } from '../../../../../utils/types/types'
import DynamicGrid from '../../../../demo/DynamicAGGrid.component'
import { getColumnsConfig } from './ExportArchive.config'

export default function ExportArchive(): ReactElement {
    const translate = useTranslation().t
    const [documents, setDocuments] = useState()

    useEffect(() => {
        getExportedArchiveDocuments()
    }, [])

    const getExportedArchiveDocuments = async () => {
        getAllPagesHandlerPrompt(
            API_ENDPOINTS.documentByType(DocumentType.QueuedInvoicesCsvFile),
            (data: any) => {
                setDocuments(
                    (prevValue) => [...(prevValue || []), ...data] as any
                )
            }
        )
    }

    return (
        <DynamicGrid
            tableId="exportArchiveTable"
            columns={getColumnsConfig(translate)}
            rowData={documents}
            pagination={true}
            rowMultiSelectWithClick={false}
        />
    )
}
