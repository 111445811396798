import { AgChartsReact } from 'ag-charts-react'
import { ReactElement, useEffect, useState } from 'react'

import { useToast } from '@chakra-ui/react'

import { useTranslation } from 'react-i18next'
import API_ENDPOINTS from '../../../services/API/apiEndpoints.constants'
import { generalGetAPI } from '../../../services/API/general.api'
import { baseSuccessToastOptions } from '../../../utils/functions.utils'
import { ContractsPaymentsPerMonthDto } from '../../../utils/types/types'

const ForecastContractsPaymentsPerMonthChart = (): ReactElement => {
    const translate = useTranslation().t
    const toast = useToast()
    const [totalPaymentsData, setTotalPaymentsData] = useState<
        ContractsPaymentsPerMonthDto[]
    >([])

    const getContractsPerProduct = async (): Promise<void> => {
        try {
            const response: any = await generalGetAPI(
                API_ENDPOINTS.forecastContractsPaymentPerMonth
            )
            if (response?.data && response?.isOk) {
                setTotalPaymentsData(response.data)
            }
            if (!response.isOk) {
                toast(baseSuccessToastOptions(response.message))
            }
        } catch (error: any) {
            toast(baseSuccessToastOptions(error?.message))
        }
    }

    useEffect(() => {
        getContractsPerProduct()
    }, [])

    const createChartData = (): any[] =>
        totalPaymentsData.map((item) => ({
            yearMonth: `${item.year}-${item.month}`,
            totalPayments: item.totalPeriodicPayments,
        }))

    const createOptions = (data: any[]): any => ({
        data,
        height: 500,
        title: {
            text: translate('forecastTotalPaymentsPerMonthForContracts'),
        },
        legend: {
            enabled: true,
        },
        series: [
            {
                type: 'line',
                xKey: 'yearMonth',
                yKey: 'totalPayments',
            },
        ],
        axes: [
            {
                type: 'category',
                position: 'bottom',
                title: {
                    text: translate('monthAndYear'),
                },
            },
            {
                type: 'number',
                position: 'left',
                title: {
                    text: translate('totalPayments'),
                },
                label: {
                    formatter: (params: any) => `${params.value / 1000}k`,
                },
            },
        ],
    })

    return <AgChartsReact options={createOptions(createChartData())} />
}

export default ForecastContractsPaymentsPerMonthChart
