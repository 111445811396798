import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex, useToast } from '@chakra-ui/react'

import API_ENDPOINTS from '../../../../services/API/apiEndpoints.constants'
import { generalGetAPI } from '../../../../services/API/general.api'
import { useNumberFormatterContext } from '../../../../services/contexts/NumberFormatter.context'
import { baseErrorToastOptions } from '../../../../utils/functions.utils'
import DynamicGrid from '../../../demo/DynamicAGGrid.component'
import CreateInterest from './CreateInterest.component'
import { getColumnsConfig } from './Interest.config'

function InterestTableComponent(): ReactElement {
    const [data, setData] = useState<any[]>()
    const toast = useToast()
    const translate = useTranslation().t
    const { formatValue } = useNumberFormatterContext()

    const getData = async (): Promise<void> => {
        try {
            const response = await generalGetAPI(API_ENDPOINTS.variableInterest)
            if (response.isOk) {
                setData(response.data)
            } else {
                toast(baseErrorToastOptions(response.message))
            }
        } catch (_) {
            toast(baseErrorToastOptions('variableInterestWarningMessage'))
        }
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <Flex w="100%" gap={6} flexDirection={'column'}>
            <CreateInterest onInterestCreated={() => getData()} />
            <DynamicGrid
                tableId="interestTable"
                columns={getColumnsConfig(translate, formatValue)}
                rowData={data}
                pagination={true}
                rowMultiSelectWithClick={false}
            />
        </Flex>
    )
}

export default InterestTableComponent
