import {
    MutableRefObject,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { v4 } from 'uuid'

import { Heading, TabPanel, TabPanels, useToast } from '@chakra-ui/react'

import GenericBox from '../../../../components/genericBox/genericBox'
import PageContainer, {
    TabListType,
} from '../../../../components/pageContainer/PageContainer.component'
import DynamicForm from '../../../../features/dynamicForm/DynamicForm.feature'
import {
    CustomUpdateProductCommandDTO,
    editProductFormFields,
} from '../../../../features/genericTables/productsTable/ProductActions.config'
import API_ENDPOINTS from '../../../../services/API/apiEndpoints.constants'
import {
    generalGetAPI,
    generalPutAPI,
} from '../../../../services/API/general.api'
import { useLoading } from '../../../../services/contexts/Loading.context'
import {
    baseErrorToastOptions,
    baseSuccessToastOptions,
} from '../../../../utils/functions.utils'
import {
    AssetTypeBO,
    ProductDTO,
    Recurrence,
    UpdateProductCommand,
} from '../../../../utils/types/types'
import ContractTemplateBundles from './components/Bundles/ContractTemplateBundles.component'
import ContractTemplateChecklist from './components/Checklist/ContractTemplateChecklist.component'
import EditContractTemplateDocuments from './components/Documents/ContractTemplateDocuments.component'
import ContractTemplateParameters from './components/Parameters/ContractTemplateParameters.component'
import EditProductRules from './components/Rules/ContractTemplateRules.component'
import EditContractTemplateActions from './ContractTemplateDetailsFooterActions'

function EditContractTemplate(): ReactElement {
    const { id } = useParams()
    const toast = useToast()
    const [enableSave, setEnableSave] = useState(false)
    const translate = useTranslation().t
    const formSubmitReference: MutableRefObject<HTMLButtonElement | undefined> =
        useRef()
    const [formKey, setFormKey] = useState(v4())
    const { globalLoading, stopGlobalLoading } = useLoading()
    const [product, setProduct] = useState<ProductDTO>()
    const [updateProduct, setUpdateProduct] = useState<
        Partial<CustomUpdateProductCommandDTO>
    >({
        name: '',
        description: '',
        maxAllowedAssets: 0,
        isUsageEnabled: false,
        productRules: [],
        validFromDateTime: new Date().toDateString(),
        validToDateTime: new Date().toDateString(),
        disable: false,
        recurrence: Recurrence.Once,
        isShadow: false,
        canBeDiscounted: false,
        defaultAssetTypeId: 0,
    })
    const [assetTypes, setAssetTypes] = useState<AssetTypeBO[]>([])

    useEffect(() => {
        if (product) setFormKey(v4())
    }, [product])

    const tabList: TabListType[] = [
        { text: 'details', id: 0 },
        { text: 'rules', id: 1 },
        { text: 'parameters', id: 2 },
        { text: 'documents', id: 3 },
        { text: 'contractBundles', id: 4 },
        { text: 'checklist', id: 5 },
    ]

    const handleUpdateProduct = async (
        updatedProduct: UpdateProductCommand
    ): Promise<void> => {
        const response = await generalPutAPI(
            API_ENDPOINTS.product,
            updatedProduct
        )
        if (response.isOk) {
            toast(baseSuccessToastOptions(translate('productUpdated')))
        } else {
            toast(baseErrorToastOptions(response.message))
        }
        setProduct(response?.data)
    }

    async function getProduct(): Promise<void> {
        const response = await generalGetAPI(`${API_ENDPOINTS.product}/${id}`)
        if (response.isOk) {
            setProduct(response.data)
        }
    }

    const fetchAssetTypes = async () => {
        const result = await generalGetAPI(API_ENDPOINTS.assetTypes)
        if (result?.isOk) {
            setAssetTypes(result.data)
        }
    }

    useEffect(() => {
        fetchAssetTypes()
    }, [])

    useEffect(() => {
        getProduct()
    }, [id])

    useEffect(() => {
        if (product) {
            let disableInitialValue = false
            if (
                product?.validToDateTime &&
                product.validToDateTime.length > 0
            ) {
                disableInitialValue = compareDates(
                    new Date(product.validToDateTime as string)
                )
            }

            setUpdateProduct({
                id: product.id,
                name: product.name,
                description: product.description,
                maxAllowedAssets: product.maxAllowedAssets,
                isUsageEnabled: product.isUsageEnabled,
                validFromDateTime: product.validFromDateTime,
                validToDateTime: product.validToDateTime,
                recurrence: product.recurrence,
                disable: disableInitialValue,
                isShadow: product.isShadow,
                canBeDiscounted: product.canBeDiscounted,
                defaultAssetTypeId: product.defaultAssetTypeId,
            })
        }
    }, [JSON.stringify(product)])

    function compareDates(validToDate: Date): boolean {
        return validToDate < new Date()
    }

    return (
        <PageContainer
            tabsList={tabList}
            pageFooter={
                <EditContractTemplateActions
                    onSave={() => {
                        if (
                            formSubmitReference &&
                            formSubmitReference.current
                        ) {
                            formSubmitReference.current?.click()
                        }

                        setEnableSave(true)
                    }}
                />
            }
        >
            <TabPanels>
                <TabPanel>
                    <GenericBox minH={440} maxW="xl" p={6}>
                        <DynamicForm<Partial<CustomUpdateProductCommandDTO>>
                            key={formKey}
                            data={updateProduct}
                            formFields={editProductFormFields(
                                updateProduct,
                                assetTypes
                            )}
                            onSubmit={(
                                data: CustomUpdateProductCommandDTO
                            ): void => {
                                const loadingId = globalLoading()
                                const todayDate = new Date()
                                const yesterday = new Date(todayDate)
                                yesterday.setDate(yesterday.getDate() - 1)

                                if (data.disable) {
                                    delete data.disable
                                    handleUpdateProduct({
                                        ...data,
                                        validToDateTime:
                                            yesterday.toISOString(),
                                    })
                                    setFormKey(v4())
                                } else {
                                    delete data.disable
                                    handleUpdateProduct({
                                        ...data,
                                    })
                                    setFormKey(v4())
                                }
                                stopGlobalLoading(loadingId)
                            }}
                            formSubmitReference={formSubmitReference}
                            hideSubmit
                        />
                    </GenericBox>
                </TabPanel>
                <TabPanel>
                    <Heading size={'md'} mb={4}>
                        {updateProduct.name}
                    </Heading>
                    {product && (
                        <EditProductRules
                            product={product}
                            setProduct={setProduct}
                            handleUpdateProduct={handleUpdateProduct}
                            enableSave={enableSave}
                            setEnableSave={setEnableSave}
                        />
                    )}
                </TabPanel>
                <TabPanel>
                    {product && (
                        <ContractTemplateParameters
                            productId={product.id}
                            updateProductName={updateProduct.name}
                        />
                    )}
                </TabPanel>
                <TabPanel>
                    {product && (
                        <EditContractTemplateDocuments
                            product={product}
                            enableSave={enableSave}
                            setEnableSave={setEnableSave}
                        />
                    )}
                </TabPanel>
                <TabPanel>
                    {product && <ContractTemplateBundles product={product} />}
                </TabPanel>
                <TabPanel>
                    {product && (
                        <ContractTemplateChecklist productId={product.id} />
                    )}
                </TabPanel>
            </TabPanels>
        </PageContainer>
    )
}

export default EditContractTemplate
