import { ReactElement } from 'react'

import { Box, Fade, Heading, SimpleGrid } from '@chakra-ui/react'

import { useTranslation } from 'react-i18next'
import PageContainer from '../../components/pageContainer/PageContainer.component'
import AssetVolumeChart from './components/AssetVolume.component'
import ActualContractsPaymentsPerMonthChart from './components/ActualContractsPaymentsPerMonth.component'
import ContractsPerProductChart from './components/ContractsPerProduct.component'
import ContractsPerStatusChart from './components/ContractsPerStatus.component'
import ForecastContractsPaymentsPerMonthChart from './components/ForecastContractsPaymentsPerMonth.component'
import CustomerContractsPerStatusChart from './components/CustomerContractsPerStatus.component'

function ChartContainer({ children }: any): ReactElement {
    return (
        <Box
            borderRadius={'xl'}
            boxShadow={'sm'}
            p={4}
            borderWidth={'1px'}
            borderStyle={'solid'}
            borderColor={'gray.100'}
        >
            {children}
        </Box>
    )
}

export default function DashboardPage(): ReactElement {
    const translate = useTranslation().t

    return (
        <PageContainer>
            <Fade in>
                <Heading mb={4} size={'lg'}>
                    {translate('portfolioDashboard')}
                </Heading>
                <SimpleGrid
                    columns={{ base: 1, sm: 1, lg: 1, xl: 2 }}
                    gap={{ base: 2, sm: 1, lg: 4, xl: 6 }}
                >
                    <ChartContainer>
                        <ContractsPerStatusChart />
                    </ChartContainer>
                    <ChartContainer>
                        <AssetVolumeChart />
                    </ChartContainer>
                    <ChartContainer>
                        <ContractsPerProductChart />
                    </ChartContainer>
                    <ChartContainer>
                        <ForecastContractsPaymentsPerMonthChart />
                    </ChartContainer>
                    <ChartContainer>
                        <ActualContractsPaymentsPerMonthChart />
                    </ChartContainer>
                    <ChartContainer>
                        <CustomerContractsPerStatusChart />
                    </ChartContainer>
                </SimpleGrid>
            </Fade>
        </PageContainer>
    )
}
