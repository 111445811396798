import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-quartz.css'
import './DynamicAGGrid.component.scss'

import { AgGridReact } from 'ag-grid-react'
import { ReactElement, SetStateAction, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FiColumns } from 'react-icons/fi'

import {
    Box,
    BoxProps,
    Flex,
    Input,
    Spinner,
    Tag,
    TagCloseButton,
    TagLabel,
    Text,
    useColorModeValue,
} from '@chakra-ui/react'

import GenericBox from '../../components/genericBox/genericBox'
import { formatDate } from '../../utils/localization/culture.utils'
import ColumnSidebar from './ColumnSidebar'
import { getTableLocale } from './translation'

interface IDynamicGrid {
    tableId: string
    key?: string
    columns: any
    rowData: any
    pagination?: boolean
    rowSelection?: 'multiple' | 'single'
    rowMultiSelectWithClick?: boolean
    masterDetail?: boolean
    detailCellRendererParams?: any
    onGridReady?: any
    onSelectionChanged?: any
    cellValueChanged?: any
    boxProps?: BoxProps
    headers?: any
    rowHeight?: number
    enableSearch?: boolean
    enableSidebar?: boolean
    height?: string
}

export default function DynamicGrid({
    tableId,
    key,
    columns,
    rowData,
    pagination = true,
    rowSelection = 'multiple',
    masterDetail = false,
    detailCellRendererParams = {},
    rowMultiSelectWithClick = true,
    onGridReady,
    onSelectionChanged,
    cellValueChanged,
    boxProps,
    headers,
    rowHeight,
    enableSearch = true,
    enableSidebar = true,
    height,
}: IDynamicGrid): ReactElement {
    const gridRef = useRef<any>()
    const translate = useTranslation().t
    const [gridApi, setGridApi] = useState<any>(null)
    const [filterTags, setFilterTags] = useState<any[]>([])
    const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false)

    const gridStyle = useMemo(
        () => ({
            height: height || '100%',
            width: '100%',
        }),
        []
    )
    const color = useColorModeValue('primary.500', 'secondary.500')

    const onGridReadyModified = (params: { api: SetStateAction<null> }) => {
        setGridApi(params.api) // Store the grid API on grid ready
        // Auto size columns when grid is ready
        if (onGridReady) {
            onGridReady(params)
        }
    }

    const handleSearchChange = (event: { target: { value: any } }) => {
        gridApi && gridApi.setQuickFilter(event.target.value)
    }

    const autoSizeStrategy = useMemo(
        () => ({
            type: 'fitGridWidth',
            defaultMinWidth: 150,
        }),
        []
    )

    const parseFilterModel = (filterModel: any) => {
        const newFilterTags = Object.keys(filterModel).map((field) => {
            const filter = filterModel[field]
            let conditionStrings = []
            if (filter.operator) {
                conditionStrings = filter.conditions.map(
                    (condition: any) =>
                        condition.filter ||
                        (condition.dateFrom
                            ? formatDate(condition.dateFrom)
                            : '') ||
                        condition.name ||
                        ''
                )
                return {
                    field: translate(field),
                    value: conditionStrings.join(` ${filter.operator} `),
                }
            }
            conditionStrings.push(
                filter.filter ||
                    (filter.dateFrom ? formatDate(filter.dateFrom) : '') ||
                    filter.name ||
                    ''
            )
            return {
                field: translate(field),
                value: conditionStrings.join(''),
            }
        })
        return newFilterTags
    }

    const onFilterChanged = () => {
        const filterModel = gridApi.getFilterModel()
        const newFilterTags = parseFilterModel(filterModel)
        setFilterTags(newFilterTags)
    }

    const removeFilter = (field: string) => {
        gridApi.getFilterInstance(field, (filterInstance: any) => {
            filterInstance.setModel(null)
            gridApi.onFilterChanged()
        })
        setFilterTags(filterTags.filter((tag) => tag.field !== field))
    }

    const resetAllFilters = () => {
        gridApi.setFilterModel(null)
        gridApi.onFilterChanged()
        setFilterTags([])
    }

    return (
        <Box key={key} id={tableId}>
            <Flex
                flexDirection={'row'}
                justifyContent={'space-between'}
                alignItems="center"
                gap={4}
                mb={2}
            >
                <Flex>
                    {enableSearch && (
                        <Input
                            placeholder={translate('search')}
                            onChange={handleSearchChange}
                            size="md"
                            w={250}
                        />
                    )}
                    {filterTags.map((tag, index) => (
                        <Tag
                            size="lg"
                            key={index}
                            borderRadius={'lg'}
                            px={3}
                            py={1}
                            fontSize={'small'}
                            fontWeight={'semibold'}
                            colorScheme="blue"
                            background={'blue.100'}
                            color={'blue.800'}
                            m={1}
                        >
                            <TagLabel>{`${tag.field}: ${tag.value}`}</TagLabel>
                            <TagCloseButton
                                onClick={() => removeFilter(tag.field)}
                            />
                        </Tag>
                    ))}
                    {filterTags.length > 0 && (
                        <Tag
                            size="lg"
                            borderRadius={'lg'}
                            px={3}
                            py={1}
                            fontSize={'small'}
                            fontWeight={'semibold'}
                            colorScheme="red"
                            background={'red.100'}
                            color={'red.800'}
                            m={1}
                            onClick={resetAllFilters}
                            cursor="pointer"
                        >
                            <TagLabel>{translate('reset')}</TagLabel>
                        </Tag>
                    )}
                </Flex>
                <div>{headers}</div>
            </Flex>
            <Box position="relative">
                <GenericBox
                    className={useColorModeValue(
                        'ag-theme-quartz',
                        'ag-theme-quartz-dark'
                    )}
                    style={gridStyle}
                    {...boxProps}
                >
                    <AgGridReact
                        ref={gridRef}
                        rowData={rowData}
                        columnDefs={columns}
                        autoSizeStrategy={autoSizeStrategy}
                        suppressBrowserResizeObserver={true}
                        onGridReady={onGridReadyModified}
                        onFilterChanged={onFilterChanged}
                        rowHeight={rowHeight || undefined}
                        enableCellTextSelection={true}
                        masterDetail={masterDetail}
                        detailCellRendererParams={detailCellRendererParams}
                        animateRows={true}
                        paginationPageSize={20}
                        paginationPageSizeSelector={[10, 20, 30]}
                        loadingOverlayComponent={() => (
                            <Spinner
                                thickness="4px"
                                speed="0.65s"
                                emptyColor="gray.200"
                                color={color}
                                size="xl"
                                position="absolute"
                                top="calc(50% - 16px)"
                                left="calc(50% - 16px)"
                            />
                        )}
                        gridOptions={{
                            pagination,
                            ...(!height ? { domLayout: 'autoHeight' } : {}), // If height is set, use auto
                            rowSelection,
                            suppressRowClickSelection: true,
                            rowMultiSelectWithClick,
                            includeHiddenColumnsInQuickFilter: true,
                            localeText: getTableLocale(translate),
                        }}
                        onSelectionChanged={onSelectionChanged}
                        onCellValueChanged={cellValueChanged}
                    />
                    {enableSidebar && (
                        <ColumnSidebar
                            columns={columns}
                            gridApi={gridApi}
                            isOpen={isSidebarOpen}
                            tableId={tableId}
                        />
                    )}
                </GenericBox>
                {enableSidebar && (
                    <Box
                        position="absolute"
                        bottom="15px"
                        left="15px"
                        zIndex={10}
                        cursor="pointer"
                        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                    >
                        <Text fontSize="lg">
                            <FiColumns />
                        </Text>
                    </Box>
                )}
            </Box>
        </Box>
    )
}
