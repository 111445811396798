import camelCase from 'lodash/camelCase'
/* eslint-disable @typescript-eslint/no-shadow */
import {
    MutableRefObject,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Heading, Spinner } from '@chakra-ui/react'

import GenericBox from '../../../../components/genericBox/genericBox'
import DynamicForm from '../../../../features/dynamicForm/DynamicForm.feature'
import API_ENDPOINTS from '../../../../services/API/apiEndpoints.constants'
import {
    ExternalPartnerSetup,
    InvoiceLineType,
} from '../../../../utils/types/types'
import {
    ERPSetupProduct,
    IERPAccountsComponent,
    loadProperties,
} from '../ERPSetup.config'

interface InvoiceLineTypeKeyValueMapper {
    [key: number]: string
}

export default function ERPAccountsFormComponent({
    activeSetup,
    ERPSetup,
    product,
    setInvoiceLines,
}: IERPAccountsComponent): ReactElement {
    const [erpKeyAndValuesObject, setErpKeyAndValuesObject] = useState<
        Partial<InvoiceLineTypeKeyValueMapper>
    >({})
    const [fieldsConfig, setFieldsConfig] = useState<any>({})
    const [products, setProducts] = useState<ERPSetupProduct[]>([])
    const [isLoading, setIsLoading] = useState(true)
    const translate = useTranslation().t
    const formSubmitReference: MutableRefObject<HTMLButtonElement | undefined> =
        useRef()

    const isManualCSV =
        activeSetup === ExternalPartnerSetup.ErpAlternativeFileCreation

    const loadProducts = async (): Promise<void> => {
        const loadedProducts = await loadProperties(
            `${API_ENDPOINTS.ERPSetupProducts}/${activeSetup}`
        )
        setProducts(loadedProducts)
        setTimeout(() => setIsLoading(false), 500)
    }

    useEffect((): void => {
        loadProducts()
    }, [ERPSetup])

    function parseValuesToObject(): void {
        let myValues = {}
        const formFields: any = []

        const listOfKeyValues = products?.length
            ? products?.map((product: ERPSetupProduct) => [
                  product.productNumber,
                  product.name && product.name !== ''
                      ? product.name
                      : `ProductNumber: ${product.productNumber}`,
              ])
            : []

        ERPSetup?.lineProductPairs?.forEach((keyValue) => {
            const { lineType, erpProductId } = keyValue

            myValues = {
                ...myValues,
                [keyValue.lineType]: erpProductId,
            }

            formFields.push({
                slug: lineType,
                label: camelCase(
                    InvoiceLineType[lineType] ?? `Key ${lineType}`
                ),
                inputProps: {
                    type: !isManualCSV ? 'select' : 'text',
                },
                ...(!isManualCSV
                    ? {
                          optionsKeysValues: listOfKeyValues,
                          value: `${erpProductId}`,
                      }
                    : { defaultValue: `${erpProductId}` }),
                skipTranslation: true,
            })
        })

        setErpKeyAndValuesObject(myValues)
        setFieldsConfig(formFields)
    }

    useEffect(() => {
        parseValuesToObject()
    }, [ERPSetup, JSON.stringify(products), product.productId])

    useEffect(() => {
        setIsLoading(true)
        setTimeout(() => setIsLoading(false), 500)
    }, [JSON.stringify(product)])

    const onSubmitAccounts = (values: InvoiceLineTypeKeyValueMapper): void => {
        const invoiceLines: any[] = Object.entries(values).map((elem) => ({
            erpProductId: `${elem[1]}`,
            lineType: Number(elem[0]),
            productId: product.productId,
        }))
        setInvoiceLines(invoiceLines)
    }

    return (
        <Box>
            <Heading
                display="flex"
                alignItems="center"
                mt={8}
                mb={4}
                size={'lg'}
            >
                {translate('accounts')}
            </Heading>
            <GenericBox
                p={6}
                width={'100%'}
                textAlign={'center'}
                animation="alternate"
                borderRadius={'md'}
            >
                {isLoading && <Spinner size="xl" />}
                {!isLoading &&
                    product &&
                    Object.keys(erpKeyAndValuesObject)?.length > 0 &&
                    fieldsConfig?.length > 0 && (
                        <DynamicForm<InvoiceLineTypeKeyValueMapper>
                            formSubmitReference={formSubmitReference}
                            data={
                                erpKeyAndValuesObject as InvoiceLineTypeKeyValueMapper
                            }
                            columns={[1, 1, 2, 3]}
                            formFields={fieldsConfig}
                            hideSubmit={!isManualCSV}
                            onFormChange={(): void => {
                                if (formSubmitReference) {
                                    formSubmitReference.current?.click()
                                }
                            }}
                            onSubmit={onSubmitAccounts}
                        />
                    )}
            </GenericBox>
        </Box>
    )
}
