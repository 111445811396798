import camelCase from 'lodash/camelCase'

import { ViewIcon } from '@chakra-ui/icons'
import { IconButton, Tooltip } from '@chakra-ui/react'

import {
    StyledBadge,
    StyledLink,
} from '../../../pages/demo/StyledComponents.component'
import {
    ColumnActionConfiguration,
    getContractStatusTagType,
    getSpecificKeyFromObject,
} from '../../../utils/functions.utils'
import { formatDate } from '../../../utils/localization/culture.utils'
import { ContractStatus } from '../../../utils/types/types'

export const columnsConfig = (
    translate: Function,
    navigate: Function,
    selectionEnabled: boolean
) => [
    {
        field: 'contractNumber',
        filter: 'agTextColumnFilter',
        headerName: translate('contract'),
        headerCheckboxSelection: selectionEnabled,
        checkboxSelection: selectionEnabled,
        cellRenderer: (params: any) => (
            <StyledLink
                onClick={(): void =>
                    navigate(`/contracts/${params.data.contractNumber}`)
                }
            >
                {params.data.contractNumber}
            </StyledLink>
        ),
    },
    {
        field: 'productName',
        hide: true,
        filter: 'agTextColumnFilter',
        headerName: translate('productName'),
    },
    {
        field: 'customerNumber',
        hide: true,
        filter: 'agTextColumnFilter',
        headerName: translate('customerNumber'),
    },
    {
        field: 'customerName',
        filter: 'agTextColumnFilter',
        headerName: translate('customerName'),
    },
    {
        field: 'status',
        filter: 'agTextColumnFilter',
        headerName: translate('status'),
        cellRenderer: (params: any) => (
            <StyledBadge
                variant={getContractStatusTagType(params?.data?.status)}
            >
                {translate(
                    camelCase(
                        getSpecificKeyFromObject<typeof ContractStatus>(
                            ContractStatus,
                            params.data.status
                        )
                    )
                )}
            </StyledBadge>
        ),
        valueGetter: (params: any) =>
            translate(
                camelCase(
                    getSpecificKeyFromObject<typeof ContractStatus>(
                        ContractStatus,
                        params.data.status
                    )
                )
            ),
    },
    {
        field: 'endDate',
        filter: 'agDateColumnFilter',
        headerName: translate('endDate'),
        valueGetter: (params: any) => formatDate(params?.data?.endDate),
        flex: 1,
    },
    {
        field: 'createdByName',
        hide: true,
        filter: 'agTextColumnFilter',
        headerName: translate('createdByName'),
    },
    {
        field: 'createdByBranch',
        hide: true,
        filter: 'agTextColumnFilter',
        headerName: translate('createdByBranch'),
    },
    {
        field: 'assetIds',
        filter: 'agTextColumnFilter',
        headerName: translate('identificationNumbers'),
        hide: true,
        valueGetter: (params: any) => {
            if (
                !params.data.baseAssetInfo ||
                params.data.baseAssetInfo.length === 0
            ) {
                return ''
            }

            return params.data.baseAssetInfo
                .map(
                    (asset: {
                        serialNumber: any
                        vin: any
                        registrationNumber: any
                    }) => {
                        const serialNumber = asset.serialNumber
                            ? asset.serialNumber
                            : ''
                        const vin = asset.vin ? asset.vin : ''
                        const registrationNumber = asset.registrationNumber
                            ? asset.registrationNumber
                            : ''
                        return [serialNumber, vin, registrationNumber]
                            .filter((id) => id)
                            .join(', ')
                    }
                )
                .join(' /// ')
        },
    },
    {
        ...ColumnActionConfiguration(
            translate,
            (params: any) => (
                <Tooltip label={translate('view')} placement="top" hasArrow>
                    <IconButton
                        aria-label="View"
                        size="sm"
                        variant={'outline'}
                        onClick={(): void =>
                            navigate(`/contracts/${params.data.contractNumber}`)
                        }
                        icon={<ViewIcon />}
                    />
                </Tooltip>
            ),
            100
        ),
    },
]
