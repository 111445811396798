import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { ViewIcon } from '@chakra-ui/icons'
import {
    Button,
    Flex,
    IconButton,
    Stack,
    Tooltip,
    useBoolean,
    useToast,
} from '@chakra-ui/react'

import FailedInvoiceLines from '../../../../../../app/config/download/failedInvoiceLines.config.json'
import DownloadActions from '../../../../../../components/downloadActions/DownloadActions.component'
import { InvoiceLinesTableModal } from '../../../../../../features/genericTables/invoiceLinesTable/InvoiceLinesTable.component'
import API_ENDPOINTS from '../../../../../../services/API/apiEndpoints.constants'
import {
    generalGetAPI,
    generalPostAPI,
} from '../../../../../../services/API/general.api'
import { useNumberFormatterContext } from '../../../../../../services/contexts/NumberFormatter.context'
import {
    baseErrorToastOptions,
    baseSuccessToastOptions,
    ColumnActionConfiguration,
} from '../../../../../../utils/functions.utils'
import {
    ExportStatus,
    InvoiceDTO,
    InvoiceLineDTO,
} from '../../../../../../utils/types/types'
import DynamicGrid from '../../../../../demo/DynamicAGGrid.component'
import { EXPORT_HEADERS } from '../../Export.config'

export default function FailedExportPage({
    showInvoiceLinesPopup = true,
}): ReactElement {
    const [selectedItems, setSelectedItems] = useState<InvoiceDTO[]>([])
    const { formatValue } = useNumberFormatterContext()
    const translate = useTranslation().t
    const [data, setData] = useState<InvoiceDTO[]>()
    const toast = useToast()
    const [isDownloadEnabled, setDownloadEnabled] = useState(false)
    const [invoiceLines, setInvoiceLines] = useState<InvoiceLineDTO[]>([])
    const [isInvoiceLinesOpen, setInvoiceLinesOpen] = useBoolean()
    const navigate = useNavigate()

    const onAddToQueue = async (): Promise<void> => {
        const exportedInvoicesList = selectedItems.map(
            (selectedInvoice: InvoiceDTO) => selectedInvoice.invoiceNumber
        )
        const response = await generalPostAPI(API_ENDPOINTS.export, {
            exportedInvoices: [...exportedInvoicesList],
        })

        if (!response.isOk) {
            toast(baseErrorToastOptions(response.message))
            return
        }
        setSelectedItems([])
        getData()
        toast(baseSuccessToastOptions(translate('invoiceQueueOk')))
    }

    const fields = EXPORT_HEADERS(translate, formatValue, false, true)

    useEffect(() => {
        generalGetAPI(
            `${API_ENDPOINTS.invoiceExport}?ExportStatus=${ExportStatus.Failed}`
        ).then((response) => {
            response.isOk && setData(response.data.items)
        })
    }, [])

    const selectAllElements = async (): Promise<void> => {
        data && setSelectedItems([...data])
    }

    const getData = () => {
        generalGetAPI(
            `${API_ENDPOINTS.invoiceExport}?ExportStatus=${ExportStatus.Failed}`
        ).then((response) => {
            response.isOk && setData(response.data.items)
        })
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <>
            <Stack spacing={4}>
                <DynamicGrid
                    tableId="failedExportTable"
                    columns={[
                        ...fields,
                        ColumnActionConfiguration(translate, (params: any) => (
                            <Tooltip
                                label={translate('view')}
                                placement="top"
                                hasArrow
                            >
                                <IconButton
                                    variant={'outline'}
                                    size="sm"
                                    aria-label="View"
                                    onClick={(): void =>
                                        showInvoiceLinesPopup
                                            ? (setInvoiceLines(
                                                  params?.data?.lines
                                              ),
                                              setInvoiceLinesOpen.on())
                                            : navigate(
                                                  `/financial/invoices/${params.data.invoiceNumber}`,
                                                  {
                                                      state: params.data,
                                                  }
                                              )
                                    }
                                    icon={<ViewIcon />}
                                />
                            </Tooltip>
                        )),
                    ]}
                    rowData={data}
                    onSelectionChanged={(values: any): void => {
                        const selectedNodes = values.api.getSelectedNodes()
                        const selectedData = selectedNodes.map(
                            (node: any) => node.data
                        )
                        setSelectedItems(selectedData)
                    }}
                    headers={
                        <Flex gap={2}>
                            <Button
                                isDisabled={!selectedItems?.length}
                                onClick={onAddToQueue}
                            >
                                {translate('addToQueue')}
                            </Button>
                            <DownloadActions
                                isDownloadEnabled={isDownloadEnabled}
                                setDownloadEnabled={setDownloadEnabled}
                                selectAllElements={selectAllElements}
                                selectedItems={selectedItems}
                                downloadConfig={FailedInvoiceLines}
                                resetSelectedItems={() => setSelectedItems([])}
                            />
                        </Flex>
                    }
                />
            </Stack>
            {isInvoiceLinesOpen && showInvoiceLinesPopup && (
                <InvoiceLinesTableModal
                    invoiceLines={invoiceLines}
                    isModalOpen={isInvoiceLinesOpen}
                    modalTitle={translate('invoiceLines')}
                    onClose={(): void => {
                        setInvoiceLinesOpen.off()
                    }}
                />
            )}
        </>
    )
}
