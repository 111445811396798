import { ChangeEvent, ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
    Box,
    FormControl,
    FormLabel,
    Grid,
    Select,
    Spinner,
} from '@chakra-ui/react'

import GenericBox from '../../../../components/genericBox/genericBox'
import API_ENDPOINTS from '../../../../services/API/apiEndpoints.constants'
import { generalGetAPI } from '../../../../services/API/general.api'
import { ExternalPartnerSetup } from '../../../../utils/types/types'
import { useERP } from '../../../../services/erp/ERP.services'

export default function ERPSetupProduct(): ReactElement {
    const {
        activeSetup,
        products,
        product,
        ERPSetup,
        updateProduct,
        onChangeProductHandler,
    } = useERP()
    const erpParameters = ERPSetup?.erpSetups || []
    const translate = useTranslation().t
    const [customerGroups, setCustomerGroups] = useState([])
    const [paymentTerms, setPaymentTerms] = useState([])
    const [layouts, setLayouts] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    const loadAllElements = async (): Promise<void> => {
        setIsLoading(true)
        const response = await generalGetAPI(
            `${API_ENDPOINTS.ERPSetupAllData}/${activeSetup}`
        )

        if (response.isOk && response.data) {
            if (response.data?.customerGroups) {
                setCustomerGroups(response.data.customerGroups)
            }
            if (response.data?.paymentTerms) {
                setPaymentTerms(response.data.paymentTerms)
            }
            if (response.data?.layouts) {
                setLayouts(response.data.layouts)
            }
        }
        setIsLoading(false)
    }

    useEffect(() => {
        loadAllElements()
    }, [activeSetup])

    return (
        <Box
            width={'100%'}
            textAlign={'center'}
            animation="alternate"
            boxShadow={'sm'}
            borderRadius={'md'}
        >
            <GenericBox w={'100%'} p={6}>
                {isLoading && <Spinner size="xl" />}
                {product && !isLoading && (
                    <>
                        <Grid
                            gridTemplateColumns={{
                                base: '1fr',
                                md: 'repeat(3, 1fr)',
                            }}
                            gap={{ base: '1rem' }}
                            mb={4}
                        >
                            <FormControl>
                                <FormLabel>{translate('product')}</FormLabel>
                                <Select
                                    key={product.productId}
                                    defaultValue={product.productId}
                                    onChange={(e) =>
                                        onChangeProductHandler(e, erpParameters)
                                    }
                                >
                                    <option hidden disabled selected value="">
                                        {translate('select')}
                                    </option>
                                    {products &&
                                        products?.map((elem: any) => (
                                            <option
                                                key={elem.id}
                                                value={elem.id}
                                            >
                                                {elem.name}
                                            </option>
                                        ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid
                            gridTemplateColumns={{
                                base: '1fr',
                                md: 'repeat(3, 1fr)',
                            }}
                            gap={{ base: '1rem' }}
                        >
                            {customerGroups && customerGroups.length > 0 && (
                                <FormControl>
                                    <FormLabel>
                                        {translate('customerGroup')}
                                    </FormLabel>

                                    <Select
                                        key={product.productId}
                                        onChange={(e) =>
                                            updateProduct({
                                                ...product,
                                                defaultCustomerGroup: parseInt(
                                                    e.target.value
                                                ),
                                            })
                                        }
                                        value={product?.defaultCustomerGroup}
                                    >
                                        <option
                                            hidden
                                            disabled
                                            selected
                                            value=""
                                        >
                                            {translate('select')}
                                        </option>
                                        {customerGroups &&
                                            customerGroups?.map((elem: any) => (
                                                <option
                                                    key={elem.groupNumber}
                                                    value={elem.groupNumber}
                                                >
                                                    {elem.name}
                                                </option>
                                            ))}
                                    </Select>
                                </FormControl>
                            )}
                            {activeSetup !==
                                ExternalPartnerSetup.BusinessCentralErp &&
                                layouts &&
                                layouts.length > 0 && (
                                    <FormControl>
                                        <FormLabel>
                                            {translate('layout')}
                                        </FormLabel>
                                        <Select
                                            key={product.productId}
                                            onChange={(e) =>
                                                updateProduct({
                                                    ...product,
                                                    defaultLayout: parseInt(
                                                        e.target.value
                                                    ),
                                                })
                                            }
                                            value={product?.defaultLayout}
                                        >
                                            <option
                                                hidden
                                                disabled
                                                selected
                                                value=""
                                            >
                                                {translate('select')}
                                            </option>
                                            {layouts &&
                                                layouts?.map((elem: any) => (
                                                    <option
                                                        key={elem.layoutNumber}
                                                        value={
                                                            elem.layoutNumber
                                                        }
                                                    >
                                                        {elem.name}
                                                    </option>
                                                ))}
                                        </Select>
                                    </FormControl>
                                )}
                            {paymentTerms &&
                                paymentTerms.length > 0 &&
                                product && (
                                    <FormControl>
                                        <FormLabel>
                                            {translate('paymentTerm')}
                                        </FormLabel>
                                        <Select
                                            key={product.productId}
                                            disabled={!paymentTerms.length}
                                            onChange={(
                                                event: ChangeEvent<HTMLSelectElement>
                                            ): void =>
                                                updateProduct({
                                                    ...product,
                                                    defaultPaymentTerm:
                                                        event.target.value,
                                                })
                                            }
                                            value={product?.defaultPaymentTerm}
                                        >
                                            <option
                                                hidden
                                                disabled
                                                selected
                                                value
                                            >
                                                {translate('select')}
                                            </option>
                                            {paymentTerms?.map((elem: any) => (
                                                <option
                                                    key={elem.paymentTermNumber}
                                                    value={
                                                        elem.paymentTermNumber
                                                    }
                                                >
                                                    {elem.name}
                                                </option>
                                            ))}
                                        </Select>
                                    </FormControl>
                                )}
                        </Grid>
                    </>
                )}
            </GenericBox>
        </Box>
    )
}
