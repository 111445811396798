import filter from 'lodash/filter'
import { ChangeEvent, ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Select, useToast } from '@chakra-ui/react'

import ContractAssetsTable from '../../../../../features/genericTables/contractAssetsTable/ContractAssetsTable.component'
import withModalHOC from '../../../../../hoc/modal.hoc'
import API_ENDPOINTS from '../../../../../services/API/apiEndpoints.constants'
import {
    generalGetAPI,
    generalPostAPI,
    generalPutAPI,
} from '../../../../../services/API/general.api'
import { useContractService } from '../../../../../services/contract/Contract.services'
import {
    baseErrorToastOptions,
    baseSuccessToastOptions,
} from '../../../../../utils/functions.utils'
import { getAllPagesHandlerPrompt } from '../../../../../utils/pagination/pagination.util'
import {
    ContractDTO,
    ContractRelationType,
    DocumentMetaDataDto,
    DocumentType,
    ProductType,
} from '../../../../../utils/types/types'
import {
    hasGeneratedClosingContract,
    ICloseContract,
    isChildContract,
} from './CloseContract.logic'
import CloseContractActions from './components/CloseContractActions.components'
import CloseContractCustomer from './components/CloseContractCustomer.component'
import CloseContractSummary from './components/CloseContractSummary.component'

function CloseContract(props: ICloseContract): ReactElement {
    const { contract, product } = useContractService()
    const translate = useTranslation().t
    const toast = useToast()
    const [closingDocLoading, setClosingDocLoading] = useState(true)
    const [invoicesLoading, setIsLoadingInvoices] = useState(false)
    const [closingContractLoading, setClosingContractLoading] = useState(false)
    const [
        hasGeneratedClosingContractValue,
        setHasGeneratedClosingContractValue,
    ] = useState(false)
    const [canCloseContract, setCanCloseContract] = useState(false)
    const [canGenerateEndInvoice, setCanGenerateEndInvoice] = useState(false)
    const [childContractsList, setChildContractsList] = useState<ContractDTO[]>(
        []
    )
    const [selectedChildContract, setSelectedChildContract] = useState('')
    const [checkContractMessage, setCheckContractMessage] = useState('')

    useEffect((): void => {
        hasGeneratedClosingContractHandler()
        checkContractHandler()

        if (isChildContract(contract)) {
            getAllPagesHandlerPrompt(
                API_ENDPOINTS.contract,
                (data: ContractDTO[]) => {
                    const filteredData: ContractDTO[] = filter(data, {
                        parentContractNumber: contract.contractNumber,
                        parentContractRelationType:
                            ContractRelationType.Prolongation,
                    })
                    setChildContractsList(filteredData)
                }
            )
        }
    }, [])

    const hasGeneratedClosingContractHandler = async (): Promise<void> => {
        const response = await generalGetAPI(
            `${API_ENDPOINTS.documentByContract}${contract.contractNumber}`
        )

        if (response.isOk) {
            const documents: DocumentMetaDataDto[] = response.data
            const hasGenerated = hasGeneratedClosingContract(documents)
            setHasGeneratedClosingContractValue(hasGenerated)
        } else {
            toast(baseErrorToastOptions(response.message))
        }
        setClosingDocLoading(false)
    }

    const checkContractHandler = async (): Promise<void> => {
        setClosingContractLoading(true)
        const response = await generalPostAPI(
            API_ENDPOINTS.contractActionsCheckContract,
            { contractNumber: contract.contractNumber }
        )
        const { data } = response
        if (data.message) {
            setCheckContractMessage(data.message)
        }

        setCanCloseContract(data.canCloseContract)
        setCanGenerateEndInvoice(
            data.canGenerateEndInvoice ||
                product.productType === ProductType.Subscription ||
                product.productType === ProductType.Rental
        )
        setClosingContractLoading(false)
    }

    const generateClosingDocument = async () => {
        if (contract?.contractNumber && !hasGeneratedClosingContractValue) {
            setClosingDocLoading(true)
            const response = await generalPostAPI(
                API_ENDPOINTS.documentGenerate,
                {
                    contractNumber: contract.contractNumber,
                    documentType: DocumentType.ContractClosing,
                }
            )

            setClosingDocLoading(false)
            if (response.isOk) {
                toast(
                    baseSuccessToastOptions(
                        translate('closingDocumentSuccessfully')
                    )
                )
                hasGeneratedClosingContractHandler()
            } else {
                toast(baseErrorToastOptions(response.message))
            }
        }
    }

    const generateEndInvoice = async (): Promise<void> => {
        if (contract?.contractNumber && contract?.customerNumber) {
            setIsLoadingInvoices(true)
            try {
                const response = await generalPostAPI(
                    API_ENDPOINTS.invoiceActionsGenerateEndInvoice,
                    {
                        contractNumber: contract.contractNumber,
                        customerNumber: contract.customerNumber,
                    }
                )

                setIsLoadingInvoices(false)
                if (response.isOk) {
                    toast(
                        baseSuccessToastOptions(translate('invoiceLineCreated'))
                    )
                    setCanGenerateEndInvoice(false)
                    checkContractHandler()
                } else {
                    toast(baseErrorToastOptions(response.message))
                }
            } catch (error) {
                setIsLoadingInvoices(false)
                toast(baseErrorToastOptions('Error generating end invoice'))
                console.error(error)
            }
        }
    }

    const handleCloseContract = async (): Promise<void> => {
        if (contract?.contractNumber) {
            setClosingContractLoading(true)
            const response = await generalPutAPI(
                API_ENDPOINTS.contractActionsCloseContract,
                {
                    contractNumber: contract.contractNumber,
                }
            )

            setClosingContractLoading(false)
            if (response.isOk) {
                toast(
                    baseSuccessToastOptions(
                        translate('closingContractSuccessfully')
                    )
                )
                window.location.reload()
            } else {
                toast(baseErrorToastOptions(response.message))
            }
            props.onClose()
        }
    }

    const handleProlongContract = async () => {
        console.log(
            'handleProlongContract',
            contract,
            childContractsList,
            selectedChildContract
        )
    }

    return (
        <Box>
            {isChildContract(contract) && (
                <>
                    <Box textTransform={'capitalize'} mt={4} mb={4}>
                        {translate('childContracts')}
                    </Box>
                    <Select
                        onChange={(
                            event: ChangeEvent<HTMLSelectElement>
                        ): void => {
                            setSelectedChildContract(event.target.value)
                        }}
                        placeholder={translate('selectContract')}
                    >
                        {childContractsList.map((c, key) => (
                            <option key={key} value={c.contractNumber}>
                                {c.contractNumber}
                            </option>
                        ))}
                    </Select>
                </>
            )}

            <Box textTransform={'capitalize'} mt={4} mb={4}>
                {translate('assets')}
            </Box>
            <ContractAssetsTable />

            {contract?.customerName && contract?.customerNumber && (
                <CloseContractCustomer
                    customerName={contract.customerName}
                    customerNumber={contract.customerNumber}
                />
            )}

            <CloseContractSummary
                hasGeneratedClosingContract={hasGeneratedClosingContractValue}
                checkContractMessage={checkContractMessage}
            />

            {contract?.status && (
                <CloseContractActions
                    canCloseContract={canCloseContract}
                    canGenerateEndInvoice={canGenerateEndInvoice}
                    closingContractLoading={closingContractLoading}
                    closingDocLoading={closingDocLoading}
                    contractStatus={contract.status}
                    handleCloseContract={handleCloseContract}
                    handleGenerateClosingDocument={generateClosingDocument}
                    handleGenerateEndInvoice={generateEndInvoice}
                    handleProlongContract={handleProlongContract}
                    hasGeneratedClosingContract={
                        hasGeneratedClosingContractValue
                    }
                    invoicesLoading={invoicesLoading}
                    isChildContract={isChildContract(contract)}
                />
            )}
        </Box>
    )
}

export default withModalHOC(CloseContract)
