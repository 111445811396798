import React, { useState, useEffect } from 'react'
import { Box, Checkbox, VStack } from '@chakra-ui/react'

interface ColumnSidebarProps {
    columns: any[]
    gridApi: any
    isOpen: boolean
    tableId: string
}

const ColumnSidebar: React.FC<ColumnSidebarProps> = ({
    columns,
    gridApi,
    isOpen,
    tableId,
}) => {
    const [columnState, setColumnState] = useState<any[]>([])

    useEffect(() => {
        if (gridApi) {
            const savedState = localStorage.getItem(`columnState_${tableId}`)
            if (savedState) {
                const parsedState = JSON.parse(savedState)
                setColumnState(parsedState)
                parsedState.forEach((col: any) => {
                    gridApi.setColumnVisible(col.field, !col.hide)
                })
            } else {
                const state = gridApi.getColumnState()
                const updatedColumns = columns.map((col) => ({
                    ...col,
                    hide:
                        state.find(
                            (stateCol: any) => stateCol.colId === col.field
                        )?.hide || false,
                }))
                setColumnState(updatedColumns)
            }
        }
    }, [gridApi, columns, tableId, isOpen])

    const handleColumnToggle = (colId: string) => {
        const newColumnState = columnState.map((col) => {
            if (col.field === colId) {
                col.hide = !col.hide
                gridApi.setColumnVisible(colId, !col.hide)
            }
            return col
        })
        setColumnState(newColumnState)
        gridApi.sizeColumnsToFit()
        localStorage.setItem(
            `columnState_${tableId}`,
            JSON.stringify(newColumnState)
        )
    }

    if (!isOpen) return <></>

    return (
        <Box
            backgroundColor="white"
            borderRight="1px solid #ccc"
            height="100%"
            left={isOpen ? 0 : '-220px'}
            p={4}
            position="absolute"
            top={0}
            transition="left 0.3s"
            width="200px"
            zIndex={10}
        >
            <VStack
                align="start"
                backgroundColor="white"
                maxH={'100%'}
                overflowY={'auto'}
            >
                {columnState
                    .filter((column) => !column.lockVisible)
                    .map((column) => (
                        <Checkbox
                            key={column.field}
                            isChecked={!column.hide}
                            onChange={() => handleColumnToggle(column.field)}
                        >
                            {column.headerName}
                        </Checkbox>
                    ))}
            </VStack>
        </Box>
    )
}

export default ColumnSidebar
